/* 
  _old_
-----------------------------------------------*/
// component(c-link-img--xxx)に変更予定

.u-hover-trans
  display block
  transition opacity .2s ease-out
  &:hover
    opacity .6

.u-hover-zoom
  display block
  overflow hidden
  img
    transition 0.5s ease-in-out
  &:hover
    img
      transform scale(1.05)
      transform-origin center