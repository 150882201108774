/* 
  _new_
-----------------------------------------------*/
.c-link-text
  color color-link
  text-decoration underline
  position relative
  &[target="_blank"]
    &::before
      content none
    &::after
      position relative
      display inline-block
      content ''
      background-image url('/_assets/img/_common/icon_blank.svg')
      background-size contain
      width em(13)
      height em(11)
      margin-left em(6)
      margin-right em(6)
  &:hover
    text-decoration none
  &[href*=".pdf"]
    padding-right 2.6em
    &::after
      position absolute
      right 0
      bottom 0.3em
      margin 0
      width em(6)
      height em(10)
      background-image url('/_assets/img/_common/icon_arrow.svg')
    &::before
      position absolute
      right 1em
      bottom 0
      display inline-block
      content ''
      width em(16)
      height em(22)
      background-image url('/_assets/img/_common/icon_pdf.svg')
      background-size contain
      background-repeat no-repeat
      background-position center right
  &.-iconArrow
    position relative
    transition opacity .2s ease-out
    color color-link
    &::after
      position relative
      display inline-block
      content ''
      background-image url('/_assets/img/_common/icon_arrow.svg')
      background-repeat no-repeat
      background-position center right
      background-size contain
      width em(6)
      height em(10)
      margin 0 em(4) 0 em(8)
    &[href^="#"]
      &::before
        content none
      &::after
        position relative
        display inline-block
        content ''
        background-image url('/_assets/img/_common/icon_arrow.svg')
        background-repeat no-repeat
        background-position center right
        background-size contain
        width em(6)
        height em(10)
        margin 0 em(4) 0 em(8)
        rotate 90deg


.c-link-image
  display inline-block
  vertical-align bottom
  transition opacity 0.5s ease-in-out
  &:hover
    opacity 0.8
  &.-zoom
    overflow hidden
    img
      transition transform 0.5s ease-in-out
    &:hover
      opacity 1
      img
        transform scale(1.05)
        transform-origin center



/* 
  _old_
-----------------------------------------------*/
.c-link
  position relative
  transition opacity .2s ease-out
  color color-link
  text-decoration underline
  &::after
    position relative
    display inline-block
    content ''
    background-image url('/_assets/img/_common/icon_arrow.svg')
    background-repeat no-repeat
    background-position center right
    background-size contain
    width em(6)
    height em(10)
    margin 0 em(4) 0 em(8)
  &:hover
    text-decoration none
  &[target="_blank"]
    &::before
      content none
    &::after
      background-image url('/_assets/img/_common/icon_blank.svg')
      width em(13)
      height em(11)
      margin-left em(6)
  &[href*=".pdf"]
    padding-right 2.6em
    &::after
      position absolute
      right 0
      bottom 0.3em
      margin 0
      width em(6)
      height em(10)
      background-image url('/_assets/img/_common/icon_arrow.svg')
    &::before
      position absolute
      right 1em
      bottom 0
      display inline-block
      content ''
      width em(16)
      height em(22)
      background-image url('/_assets/img/_common/icon_pdf.svg')
      background-size contain
      background-repeat no-repeat
      background-position center right

.c-linkContainer
  display flex
  justify-content center
  gap size-minGrid*10
  margin-bottom size-minGrid*10
  &.-center
    align-items center

.c-link-rightArrowCircle
  display flex
  align-items center
  gap size-minGrid*2
  color color-link
  &:hover
    text-decoration underline
  &::before
    display block
    content ""
    width size-minGrid*4
    height size-minGrid*4
    background-image url("/_assets/img/_common/icon_link.svg")
    background-repeat no-repeat
    background-position center

.c-link-textUnderline
  padding-right 2em
  padding-bottom 0.3em
  border-bottom 2px solid currentColor
  position relative
  display inline-block
  &::after
    content: ""
    display block
    width size-minGrid*1.5
    height size-minGrid*1.5
    background-color currentColor
    border-radius 100vh
    position absolute
    top 0.7em
    right 0.2em
    transform-origin center
  &, &:visited
    color color-base-text
  &:hover
    color color-primary
    &::after
      transform scale(1.2)

.c-link-leftArrowUnderline
  text-align center
  color color-link
  font-size size-font--large
  font-weight bold
  &__inner
    position relative
    display inline-block
    text-decoration underline
    padding-bottom 1em
    transition opacity .2s ease-out
    &::before
      position relative
      display inline-block
      content ''
      background-image url('/_assets/img/_common/icon_arrow.svg')
      background-repeat no-repeat
      background-position center right
      background-size contain
      width em(7)
      height em(12)
      margin-right em(4)
    &::after
      position absolute
      bottom 0
      left 50%
      transform translateX(-50%)
      display inline-block
      content ''
      width 80px
      height 3px
      background-color color-secondary
    &:hover
      opacity .6