/* 
  _new_
-----------------------------------------------*/
breakpoint(size, type = max)
  breakpoints = {sm:660,smd:960,md:1025,lg:1600}
  if type == max
    @media only screen and (max-width: unit(breakpoints[size],px))
      {block}
  else if type == min
    @media only screen and (min-width: unit(breakpoints[size] + 1 ,px))
      {block}

vw(num, width = 1200) {
  (1vw * num / width * 100)
}

em(px, fontSize = 15) {
  (px / fontSize) * 1em;
}

verticalLayout(gap)
  display flex
  flex-direction column
  gap gap


/* 
  _old_
-----------------------------------------------*/
heading(lv)
  font-weight bold
  margin-bottom size-minGrid*5
  if lv == 1
    font-size 3.6rem
    letter-spacing 0.1em
    &::before
      display block
      content ""
      width size-minGrid*14
      height 3px
      background-color color-primary
      margin-bottom 1em
  if lv == 2
    font-size size-font--xlarge
    padding-left 1.5em
    position relative
    &::before
      display block
      content ""
      width 0.5em
      height 0.5em
      background-color color-primary
      border-radius 100%
      position absolute
      left 0
      top 0
      bottom 0
      margin auto
  if lv == 3
    border-bottom dashed 1px
    padding-bottom size-minGrid
  if lv == 4
    position relative
    padding 0 0 size-minGrid*1 size-minGrid*6
    border-left 5px solid color-primary
    font-size clamp(2.2rem,vw(26),size-font--xxxlarge)
    line-height 1.5
    font-weight bold
    +breakpoint(sm)
      padding 0 0 0 size-minGrid*4
      font-size size-font--xlarge

// news
news-boxBg(bgcolor)
  background-color bgcolor
  padding size-minGrid*5

news-heading(lv)
  color color-primary
  font-weight bold
  margin-bottom size-minGrid*5
  if lv == 1
    font-size size-font--large
  if lv == 2
    font-size size-font--base
    border-bottom dotted 1px color-primary
    padding-bottom size-minGrid*2
  if lv == 3
    background-color color-contentsPointBackground
    padding size-minGrid*2 size-minGrid*3