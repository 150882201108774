/*
  _new_
-----------------------------------------------*/
.c-block-outlined
  padding size-minGrid*5
  border 1px solid color-border


.c-block-filled
  padding size-minGrid*5
  background color-contentsPointBackground
  &.-rounded
    border-radius size-minGrid
  &.-pdMedium
    padding size-minGrid*8
  &.-pdLarge
    padding size-minGrid*10
  &.-filledColorContentsBackground
    background-color color-contentsBackground
  +breakpoint(sm)
    &.-pdLarge
      padding size-minGrid*5


.c-block-typeA
  padding size-minGrid*5
  border 1px solid color-border
  background-color #fff
  border-radius size-minGrid
  &.-square
    border-radius 0
  &.-pdMedium
    padding size-minGrid*8
  &.-pdLarge
    padding size-minGrid*10
  &.-outlineColorPrimary
    border-color color-primary
  &.-modifierA
    background-color color-contentsPointBackground
    border 1px solid color-blue-light
  &.-modifierB
    background-color color-contentsBackground
    border 1px solid color-border
  &.-modifierC
    border size-minGrid solid color-blue-light
  +breakpoint(sm)
    padding size-minGrid*5
    &.-pdLarge
      padding size-minGrid*5
    &.-pdMedium
      padding size-minGrid*5


.c-block-typeB
  border solid 2px color-primary
  padding 14px size-minGrid*5
  text-align center
  color color-primary
  font-weight bold
  line-height 1.3
  border-radius size-minGrid
  &.-colorSecondary
    border solid 2px color-secondary
    color color-secondary
  &.-modifierA
    padding size-minGrid*2 size-minGrid*5
    border 2px solid color-primary
    text-align center
    font-weight 700
    font-size size-font--xlarge
    color color-base-text
    border-radius 0
    +breakpoint(sm)
      font-size 1.6rem


.c-block-typeC
  background color-contentsPointBackground
  padding size-minGrid*9 size-minGrid*8
  &__inner
    background #fff
    padding size-minGrid*13
    border-radius 6px
  &.-filledImage
    background-size cover
    background-repeat no-repeat
    background-position center center
    & ^[0]__inner
      background rgba(255, 255, 255, 0.8)
  +breakpoint(sm)
    padding size-minGrid*5
    &__inner
      padding size-minGrid*5

.c-block-typeD
  position relative
  padding size-minGrid*6
  border solid 1px color-primary
  border-radius size-minGrid
  padding size-minGrid*6
  verticalLayout(size-minGrid*5)
  margin-top 15px
  background-color #fff
  &__label
    position absolute
    top -15px
    left size-minGrid*3
    background-color color-primary
    border-radius size-minGrid*4
    color color-primary-text
    font-family font-lato
    padding 9px 18px 7px
    line-height 1
  &__content
    verticalLayout(size-minGrid*5)

.c-block-typeE
  border solid 2px color-primary
  border-radius size-minGrid
  padding 0
  verticalLayout(size-minGrid*0)
  text-align center
  background-color #fff
  &__heading
    font-weight bold
    color color-primary-text
    background-color color-primary
    padding size-minGrid*3
    line-height 1.3
  &__content
    padding size-minGrid*5
    verticalLayout(size-minGrid*5)
  &.-colorSecondary
    border solid 2px color-secondary
    & ^[0]__heading
      background-color color-secondary
