/* 
  _old_
-----------------------------------------------*/
// componentに変更予定

.u-marker
  border-bottom 1px solid
  padding 0 size-minGrid

.u-marker-red
  border-bottom 1px solid color-red
  padding 0 size-minGrid