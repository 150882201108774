/*
  _new_
-----------------------------------------------*/
.c-contentsSection
  verticalLayout(size-minGrid*10)
  &__body
    verticalLayout(size-minGrid*10)
    &.-gapXsmall
      gap size-minGrid*6
    &.-gapSmall
      gap size-minGrid*8
    &.-gapMedium
      gap size-minGrid*15
    &.-gapLarge
      gap size-minGrid*20
  &.-modifierA
    & ^[0]__body
      padding size-minGrid*8
      background-color #fff
      border 1px solid color-border

  /* _old_ 削除予定 */
  &.-body-box
    & ^[0]__body
      padding size-minGrid*8
      background-color #fff
      border 1px solid color-border
  /* _old_ ここまで */

  +breakpoint(md)
    gap size-minGrid*7
  +breakpoint(sm)
    gap size-minGrid*6
    &__body
      gap size-minGrid*6
      &.-gapXsmall
        gap size-minGrid*4
      &.-gapSmall
        gap size-minGrid*5
      &.-gapMedium
        gap size-minGrid*8
      &.-gapLarge
        gap size-minGrid*10
    &.-modifierA
      & ^[0]__body
        padding size-minGrid*5

    /* _old_ 削除予定 */
    &.-body-box
      & ^[0]__body
        padding size-minGrid*5 size-minGrid*5
    /* _old_ ここまで */

