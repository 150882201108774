headingFont(weight = 500, height = 1.3)
  font-family "Lato", "Zen Kaku Gothic New", sans-serif
  font-weight weight
  line-height height

/* 
  _new_
-----------------------------------------------*/
.c-heading-typeA
  headingFont()
  font-size 3.6rem
  letter-spacing 0.1em
  &::before
    display block
    content ""
    width size-minGrid*14
    height 3px
    background-color color-primary
    margin-bottom 1em
  +breakpoint(md)
    font-size 3.2rem
  +breakpoint(sm)
    font-size size-font--xxxlarge
    &::before
      margin-bottom .8em


.c-heading-typeB
  headingFont()
  padding-left 1em
  position relative
  font-size size-font--xxlarge
  &::before
    display block
    content ""
    width 9px
    height 9px
    background-color color-primary
    border-radius 100%
    position absolute
    left 0
    top 0
    bottom 0
    margin auto
  &.-textLarge
    font-size size-font--xxxxlarge
    padding-left 0.8em
  &.-textSmall
    font-size size-font--xlarge
  &.-underlined
    border-bottom 1px solid currentColor
    padding-bottom size-minGrid*2
    &::before
      bottom size-minGrid*1.5
  +breakpoint(sm)
    font-size size-font--large
    &::before
      width 7px
      height 7px
    &.-textSmall
      font-size size-font--large
    &.-textLarge
      font-size size-font--xxlarge


.c-heading-typeC
  headingFont()
  font-size size-font--xxxlarge
  border-left 5px solid color-primary
  padding-left size-minGrid*4
  +breakpoint(sm)
    font-size size-font--xlarge


.c-heading-typeD
  headingFont()
  display flex
  align-items flex-end
  gap size-minGrid*3
  border-bottom 1px solid color-primary
  font-size size-font--xlarge
  &__number
    display flex
    align-items center
    justify-content center
    min-width em(36,20)
    width em(36,20)
    height em(36,20)
    background-color color-primary
    color color-primary-text
    text-align center
    font-family font-lato
    font-weight 700
  &__text
    display flex
    align-items center
    padding 0.2em 0
  &.-filledColorBlack
    border-color color-base-text
    & ^[0]__number
      background-color color-base-text
  +breakpoint(sm)
    font-size size-font--large


.c-heading-typeE
  --accent-color color-primary
  headingFont()
  position relative
  text-align center
  font-size size-font--xxxxlarge
  &::after 
    content ""
    display block
    width 50px
    height 3px
    background-color var(--accent-color)
    margin size-minGrid*5 auto 0
  +breakpoint(sm)
    font-size size-font--xxlarge
    &::after
      margin-top size-minGrid*3


.c-heading-typeF
  headingFont(weight = 700)
  &__sub
    display block
    margin-bottom size-minGrid*1.5
    font-size size-font--large
    font-weight normal
  &__main
    font-size size-font--xxlarge
    color color-primary
  &__main:where(a)
    cursor pointer
    &:visited
      color color-primary
    &:hover
      text-decoration underline
  +breakpoint(sm)
    &__sub
      font-size size-font--medium
    &__main
      font-size size-font--large


.c-heading-typeG
  headingFont(weight = 700)
  position relative
  z-index 1
  text-align center
  padding-bottom size-minGrid*2
  &__label
    position relative
    display block
    margin 0 auto
    padding size-minGrid*5
    max-width 680px
    font-size size-font--xxlarge
    color color-green
    &::before
    &::after
      position absolute
      content ''
      display block
      width 100%
      height 100%
    &::before
      top 0
      left 0
      border 4px solid color-green
      z-index -1
    &::after
      top size-minGrid*2
      left size-minGrid*2
      background #FCF9CB
      z-index -2
  +breakpoint(md)
    &__label
      font-size 2.2rem
  +breakpoint(sm)
    &__label
      font-size size-font--xlarge
      &::before
        border-width 3px


.c-heading-typeH
  headingFont(weight = 700)
  display flex
  gap size-minGrid*3
  align-items center
  padding-bottom 0.4em
  font-size size-font--xxlarge
  border-bottom 1px solid
  &__icon
    display flex
    flex-shrink 0
    align-items center
    height size-minGrid*9
    img
      display block
      max-width size-minGrid*9
      max-height size-minGrid*9
  &__text
    flex-grow 1
    flex-shrink 1
    flex-basis auto
  +breakpoint(sm)
    font-size size-font--large
    &__icon
      img
        max-width size-minGrid*7
        max-height size-minGrid*7


.c-heading-filled
  --bg-color color-contentsPointBackground
  --text-color color-base-text
  headingFont(weight = 700)
  padding size-minGrid*4 size-minGrid*4
  background-color var(--bg-color)
  color var(--text-color)
  font-size size-font--large
  &.-filledColorWhite
    background-color #fff
  &.-filledColorBlack
    background-color color-black
    color #fff
  &.-filledColorContentsBackground
    background-color color-contentsBackground
  &.-textMedium
    font-size 2rem
  &.-textLarge
    font-size size-font--xxlarge
    padding size-minGrid*4 size-minGrid*7
  &.-textCenter
    text-align center
  +breakpoint(sm)
    font-size 1.6rem
    &.-textMedium
      font-size 1.7rem
    &.-textLarge
      font-size size-font--large
      padding size-minGrid*4 size-minGrid*4


.c-heading-textBold
  --text-color color-base-text
  headingFont(weight = 700)
  color var(--text-color)
  font-size size-font--large
  &.-textSmall
    font-size 1.6rem
  &.-textMedium
    font-size size-font--xlarge
  &.-textLarge 
    font-size size-font--xxlarge
  &.-textXlarge
    font-size size-font--xxxlarge
  &.-textColorPrimary
    color color-primary
  &.-textCenter
    text-align center
  +breakpoint(sm)
    font-size 1.7rem
    &.-textSmall
      font-size 1.6rem
    &.-textMedium
      font-size size-font--large
    &.-textLarge 
      font-size size-font--xlarge
    &.-textXlarge
      font-size size-font--xxlarge


.c-heading-section
  --bg-color color-primary
  --text-color color-primary-text
  padding 1em size-minGrid*5
  background var(--bg-color)
  color var(--text-color)
  headingFont()
  font-size size-font--xlarge
  text-align center
  +breakpoint(sm)
    font-size size-font--large


.c-heading-underlined
  padding-bottom 0.4em
  headingFont()
  font-size size-font--large
  border-bottom 1px solid color-base-text
  +breakpoint(sm)
    font-size 1.6rem




/* 
  _old_
-----------------------------------------------*/
.c-heading
  &-topLined
    headingFont()
    font-size 3.6rem
    letter-spacing 0.1em
    line-height 1.5
    &::before
      display block
      content ""
      width size-minGrid*14
      height 3px
      background-color color-primary
      margin-bottom 1em
    +breakpoint(md)
      font-size 3.2rem
    +breakpoint(sm)
      font-size size-font--xxxlarge
      &::before
        margin-bottom .8em

  &-leftLined
    headingFont()
    font-size size-font--xxxlarge
    border-left 5px solid color-primary
    padding-left size-minGrid*4
    padding-bottom size-minGrid
    line-height 1.5
    +breakpoint(sm)
      font-size size-font--xlarge

  &-square
    headingFont()
    font-size size-font--xxlarge
    border-bottom 1px solid color-border
    padding-left size-minGrid*8
    padding-bottom size-minGrid*2.5
    line-height 1.5
    position relative
    &::before
      display block
      content ""
      position absolute
      left size-minGrid
      top size-minGrid*2.5
      width size-minGrid*4
      height size-minGrid*4
      border-right 2px solid color-secondary
      background-color color-primary
      box-sizing border-box
    +breakpoint(sm)
      font-size size-font--large
      padding-bottom size-minGrid*1.5
      padding-left size-minGrid*6
      &::before
        width size-minGrid*3
        height size-minGrid*3
        top size-minGrid*2

  &-center
    text-align center
    headingFont()
    font-size size-font--xxxxlarge
    color color-primary
    line-height 1.5
    +breakpoint(sm)
      font-size size-font--xxlarge

  &-bullet
    headingFont()
    font-size size-font--xlarge
    padding-left 1.5em
    position relative
    &::before
      display block
      content ""
      width 0.5em
      height 0.5em
      background-color color-primary
      border-radius 100%
      position absolute
      left 0
      top 0
      bottom 0
      margin auto
    +breakpoint(sm)
      font-size size-font--large
      padding-left 1.3em

  &-simple
    headingFont(weight = 700)
    line-height 1.5
    font-size 1.6rem
    &.-md
      font-size size-font--medium
    &.-lg
      font-size size-font--large
    &.-xl
      font-size size-font--xlarge
    &.-xxl
      font-size size-font--xxlarge
    &.-xxxl
      font-size size-font--xxxlarge
    &.-xxxxl
      font-size size-font--xxxxlarge
    &__label
      display inline-block
      margin-right size-minGrid*3
      font-size 0.4em
      letter-spacing -0.1em
      width 4em
      height 4em
      line-height 4em
      background-color color-secondary
      color color-primary-text
      vertical-align top
      border-radius 50%
      text-align center
    +breakpoint(sm)
      font-size 1.4rem
      &.-md
        font-size 1.3rem
      &.-lg
        font-size 1.6rem
      &.-xl
        font-size size-font--large
      &.-xxxxl
        font-size size-font--xlarge

  &-num
    headingFont(weight = 700)
    display flex
    border-bottom 1px solid color-primary
    line-height 1.5
    font-weight bold
    font-size size-font--large
    &__label
      display flex
      align-items center
      justify-content center
      margin-right size-minGrid*3
      min-width em(35,18)
      width em(35,18)
      height em(35,18)
      background-color color-primary
      color color-primary-text
      text-align center
      font-family font-lato
    &__text
      display inline-block
      padding-top 2px
    +breakpoint(sm)
      font-size 1.6rem
      &__label
        width em(30,16)
        height em(30,16)

  &-bg
    headingFont(weight = 700)
    padding 10px size-minGrid*4 size-minGrid*3
    background-color color-contentsPointBackground
    font-size size-font--large
    line-height 1.5
    &.-white
      background-color #fff
    &.-black
      background-color ccolor-black
      color #fff
    &.-txt-secondary
      color color-secondary
    &.-var2
      font-size size-font--xlarge
      background-color color-primary
      text-align center
      color color-primary-text
      font-weight bold
    +breakpoint(sm)
      font-size 1.6rem
      &.-var2
        font-size 1.6rem

  &-icon
    headingFont(weight = 700)
    display flex
    gap size-minGrid*3
    align-items center
    padding-bottom size-minGrid*3
    line-height 1.5
    font-size 2.4rem
    border-bottom 1px solid
    &__image
      display flex
      flex-shrink 0
      align-items center
      height 40px
      img
        max-width 40px
        max-height 40px
        display block
    &__text
      padding-top 4px
      flex-grow 1
      flex-shrink 1
      flex-basis auto
    +breakpoint(sm)
      font-size size-font--large
      &__image
        img
          max-width 30px
          max-height 30px

  &-topSmallText
    headingFont(weight = 700)
    line-height 1.5
    &__small
      display block
    &__main
      display block
      font-size clamp(5rem,vw(72),7.2rem)
      color color-primary
      line-height 1.2
      
  &-underLine
    headingFont(weight=600)
    padding-bottom 0.2em
    line-height 1.5
    border-bottom 1px solid currentColor
    font-size size-font--xxxxlarge
    +breakpoint(sm)
      font-size size-font--xlarge

