.c-accordion-typeA
  margin size-minGrid*2 0
  summary::-webkit-details-marker
    display:none
  &__inner
    padding 0
    verticalLayout(size-minGrid*10)
  &__details
    border 1px solid color-primary
  &__details[open]
    & ^[0]__heading__icon::after
      transform translate(-50%,-50%)
  &__heading
    list-style none
    height auto
    padding size-minGrid*4 size-minGrid*18 size-minGrid*4 size-minGrid*6
    text-align left
    background-color color-contentsPointBackground
    color color-primary
    font-size clamp(1.6rem, 2.4vw ,size-font--large)
    line-height 1.5
    cursor pointer
    position relative
    +breakpoint(sm)
      padding size-minGrid*4 size-minGrid*18 size-minGrid*4 size-minGrid*6
    &__icon
      position absolute
      top 50%
      right size-minGrid*6
      padding-top 30px
      width 30px
      transform translateY(-50%)
      &::before, &::after
        position absolute
        content ''
        display block
        top 50%
        left 50%
        transform translate(-50%,-50%)
        background-color color-primary
        width size-minGrid*5
        padding-top 10%
      &::after
        transform translate(-50%,-50%) rotate(90deg)
        transition all .2s ease-out
  &__panel
    overflow hidden
    background-color #fff
    &__content
      padding size-minGrid*8
      verticalLayout(size-minGrid*5)
      +breakpoint(sm)
        padding size-minGrid*4 size-minGrid*6
    &__list
      display grid
      grid-template-columns repeat(2,1fr)
      gap  size-minGrid size-minGrid*2
    &__item
      display flex
      min-height 50px
      line-height 1.5