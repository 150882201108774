.c-checkbox-typeA
  display none
  &__label
    display inline-block
    display block
    position relative
    padding-left 22px
    cursor pointer
    line-height 1.5
    font-size 1.6rem
    &::before
      content ''
      display block
      width em(16,16)
      height em(16,16)
      border 1px solid color-primary
      background-color #fff
      position absolute
      left 0
      top em(4,16)
      border-radius 3px
    &::after
      content ''
      position absolute
      top em(9,16)
      left em(3,16)
      display block
      width em(10,16)
      height em(8,16)
      background-image url('/_assets/img/_common/icon_check_white.svg')
      background-size contain
      background-repeat no-repeat
  &:checked + .c-checkbox-typeA__label
    &::before
      background-color color-primary
  +breakpoint(sm)
    font-size size-font--medium