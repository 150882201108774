/*
  _new_
-----------------------------------------------*/
.c-emphasis-typeA
  padding 0 2px
  border-bottom 1px solid color-secondary
  color color-primary
  font-size 1.2em
  font-weight 700


.c-emphasis-textBold
  font-weight 700
  &.-textColorPrimary
    color color-primary
  &.-textColorSecondary
    color color-secondary
  &.-textColorRed
    color color-red
