/* 
  _new_
-----------------------------------------------*/
.u-color-primary
  color color-primary !important

.u-color-secondary
  color color-secondary !important

.u-color-red 
  color color-red !important

.u-color-baseText
  color color-base-text !important

.u-color-link
  color color-link !important