/* 
  _old_
-----------------------------------------------*/
// l-contentsAreaに変更

.l-contentsWrapper
  width 100%
  max-width size-contentsArea
  margin 0 auto
  padding 0 size-minGrid*10
  verticalLayout(size-minGrid*16)
  +breakpoint(md)
    padding 0 size-minGrid*5

