/* 
  _new_
-----------------------------------------------*/
.c-button-rounded
  --bg-color color-primary
  --text-color color-primary-text
  --bg-hover-color #0062d8
  display flex
  justify-content center
  align-items center
  gap size-minGrid*3
  flex-wrap nowrap
  width clamp(1px,vw(368),368px)
  max-width: 100%
  line-height 1.2
  border-radius 3em
  font-weight 500
  margin 0 auto
  padding size-minGrid*4 3em
  background var(--bg-color)
  font-size clamp(1.3rem, vw(16), 1.6rem)
  transition background-color .3s ease-out
  cursor pointer
  &, &:visited, &:hover
    color var(--text-color)
  &:hover
    background var(--bg-hover-color)
  +breakpoint(md)
    width clamp(1px,vw(368,1025px),368px)
  +breakpoint(sm)
    width 90%
    max-width 90%
  &.-modifierA
    font-size clamp(1.6rem, vw(28), size-font--xxlarge)
    &::after
      display block
      content ""
      width size-minGrid*3
      height size-minGrid*3
      margin-top size-minGrid*-1
      border-right 2px solid currentColor
      border-bottom 2px solid currentColor
      transform rotate(45deg)
      transform-origin center
      flex-shrink 0
      margin-top size-minGrid*-1
    &.-textSmall
      font-size clamp(1.6rem, vw(20), 2rem)
  &.-modifierB
    padding size-minGrid*2 size-minGrid*2
    gap 0
    font-size clamp(1.5rem, vw(20), size-font--xlarge)
    &::after
      display block
      content ""
      margin-left size-minGrid*2.5
      width 0.5em
      height 0.5em
      border-right 2px solid currentColor
      border-bottom 2px solid currentColor
      transform rotate(-45deg)
    &.-small
      font-size clamp(1.4rem, vw(15), 1.5rem)
      max-width 200px
      


.c-button-filled
  position relative
  display flex
  align-items center
  width clamp(1px,vw(368),368px)
  max-width: 100%
  margin 0 auto
  padding size-minGrid*4 size-minGrid*9  size-minGrid*4 size-minGrid*6
  background color-button-standard
  color color-primary-text
  line-height 1.5
  border-radius 4px
  border 1px solid color-button-standard
  transition all .2s ease-out
  font-size clamp(1.3rem,vw(15),size-font--medium)
  font-weight 500
  &:visited
    color color-primary-text
  &::after
    position absolute
    right size-minGrid*4
    top 50%
    transform translateY(-50%)
    content ''
    display block
    background color-primary-text
    width 6px
    height 6px
    border-radius 50%
    transition all .2s ease-out
  &[target="_blank"]
    &::after
      content: none
    svg
      display block
    path
      fill color-primary-text
  // &[href*=".pdf"]
  //   padding-left size-minGrid*15
  //   &::after
  //     content none
  //   & ^[0]__icon
  //     right auto
  //     left size-minGrid*6
  &:hover
    background-color color-button-hover
    color color-button-standard
    &::after
      background-color color-button-standard
      transform translateY(-50%) scale(1.2)
    path
      fill color-button-standard
  &__icon
    position absolute
    right size-minGrid*4
    top 50%
    transform translateY(-50%)
    transition all .2s ease-out
  &.-filledColorWhite
    background #fff
    color color-base-text
    &::after
      background color-base-text
    path
      fill color-base-text
    &:hover
      background-color color-button-standard
      color #fff
      &::after
        background-color #fff
        transform translateY(-50%) scale(1.2)
      path
        fill #fff
  &.-fitContainer
    width 100%
    height 100%
    box-sizing border-box
  +breakpoint(md)
    width clamp(1px,vw(368,1025px),368px)
    font-size 1.4rem
  +breakpoint(sm)
    width 90%
    max-width 90%
    padding size-minGrid*4 size-minGrid*8 size-minGrid*4 size-minGrid*4
    font-size 1.3rem


.c-button-typeA
  position relative
  display flex
  padding size-minGrid size-minGrid*8 size-minGrid size-minGrid*5
  min-height 54px
  height 100%
  width 100%
  align-items center
  color color-base-text
  font-size clamp(1.3rem,vw(15),size-font--medium)
  transition all .2s ease-out, color .2s ease-out
  border-left 4px solid transparent
  border-bottom 1px solid color-border
  cursor pointer
  &:visited
    color color-base-text
  &::after
    position absolute
    right size-minGrid*4
    top 50%
    transform translateY(-50%)
    content ''
    display block
    background color-base-text
    width 6px
    height 6px
    border-radius 50%
    transition all .2s ease-out
  &:hover
    border-left 4px solid color-primary
    background-color rgba(color-primary, 0.1)
  +breakpoint(sm)
    padding size-minGrid size-minGrid*6 size-minGrid size-minGrid*3
    font-size 1.3rem
    &::after
      right size-minGrid*3


.c-button-fixed
  transform translateY(100%)
  opacity 0
  transition all .3s ease-out
  position fixed
  bottom 20px
  right 20px
  pointer-events none
  padding-top size-minGrid
  z-index 2
  &__label
    line-height 1.5 
    display flex
    flex-direction column
    align-items center
    justify-content center
    border 2px solid color-primary
    background-color #fff
    color color-primary
    border-radius 5px
    width size-minGrid*15
    height size-minGrid*15
    font-size 10px
    font-weight bold
    text-align center
    cursor pointer
    &::before
      content ''
      width 26px
      height 14px
      display inline-block
      margin-bottom size-minGrid
      background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2226%22%20height%3D%2213.5%22%20viewBox%3D%220%200%2026%2013.5%22%3E%20%3Cg%20id%3D%22concept%22%3E%20%3Cpath%20d%3D%22m13%2C0c.55%2C0%2C1.11.18%2C1.56.56l10.52%2C8.54c1.06.86%2C1.23%2C2.43.36%2C3.49-.86%2C1.06-2.43%2C1.23-3.49.36l-8.95-7.27-8.95%2C7.27c-1.06.86-2.63.7-3.49-.36-.86-1.06-.7-2.63.36-3.49L11.44.56c.46-.37%2C1.01-.56%2C1.56-.56Z%22%20style%3D%22fill%3A%20%23004ba5%3B%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E')
      background-repeat no-repeat
      background-size contain
  &.is-show
    transform translateY(0)
    opacity 1
    pointer-events auto
    &:hover
      opacity 0.8
  &.is-absolute
    position absolute
    transform translateY(0)
    opacity 1
    pointer-events auto
    &:hover
      opacity 0.8
  +breakpoint(sm)
    right size-minGrid*2
    bottom size-minGrid*2


/* 
  _old_
-----------------------------------------------*/
.c-button
  display flex
  align-items center
  max-width 100%
  padding size-minGrid size-minGrid*2
  background color-button-standard
  color color-primary-text
  &-arrowRight
    height size-minGrid*15
    border 1px solid color-primary
    border-radius size-minGrid
    display flex
    align-items center
    justify-content space-between
    padding size-minGrid*4
    box-sizing border-box
    color color-base-text
    background-color color-button-light
    transition all 0.3s ease
    &::after
      display block
      content ""
      margin-left size-minGrid*2.5
      width size-minGrid*2
      height size-minGrid*2
      border-right 2px solid currentColor
      border-bottom 2px solid currentColor
      transform rotate(-45deg)
      transform-origin center
      flex-shrink 0
      flex-grow 0
    &:visited
      color color-base-text
    &:hover
      background-color color-primary
      color color-primary-text
  &-dotRight
    height size-minGrid*16
    display flex
    justify-content space-between
    align-items center
    background-color color-button-light
    padding size-minGrid*6
    color color-base-text
    font-weight 500
    box-shadow 0 3px 3px rgba(0,0,0,0.2)
    gap size-minGrid*2
    position relative
    &::after
      display block
      content ""
      width size-minGrid*1.5
      height size-minGrid*1.5
      background-color currentColor
      border-radius 100vh
      flex-shrink 0
      flex-grow 0
    &.is-current,
    .is-current &,
    &:hover
      background-color color-buttonBackground-hover
      &::before
        display block
        content ""
        width size-minGrid
        height 100%
        background-color color-primary
        flex-shrink 0
        flex-grow 0
        position absolute
        top 0
        left 0
      &::after
        color-primary
    &:hover, &:visited
      color color-base-text

  &-primary
    position relative
    display flex
    align-items center
    width clamp(1px,vw(368),368px)
    max-width: 100%
    margin 0 auto
    padding size-minGrid*4 size-minGrid*9  size-minGrid*4 size-minGrid*6
    background color-button-standard
    color color-primary-text
    line-height 1.5
    border-radius 4px
    border 1px solid color-button-standard
    transition all .2s ease-out
    font-size size-font--medium
    font-weight 500
    &:visited
      color color-primary-text
    &::after
      position absolute
      right size-minGrid*4
      top 50%
      transform translateY(-50%)
      content ''
      display block
      background color-primary-text
      width 6px
      height 6px
      border-radius 50%
      transition all .2s ease-out
    &[target="_blank"]
      &::after
        content: none
      svg
        display block
      path
        fill color-primary-text
    &[href*=".pdf"]
      padding-left size-minGrid*15
      &::after
        content ''
      & ^[0]-primary__icon
        right auto
        left size-minGrid*6
    &:hover
      background-color color-button-hover
      color color-button-standard
      &::after
        background-color color-button-standard
        transform translateY(-50%) scale(1.2)
      path
        fill color-button-standard
    &__icon
      position absolute
      right size-minGrid*4
      top 50%
      transform translateY(-50%)
      transition all .2s ease-out
    
    &.-white
      background-color #fff
      color color-base-text
      &::after
        background-color color-base-text
      &:hover
        background-color color-button-standard
        border-color color-button-standard
        color color-primary-text
        &::after
          background-color color-primary-text
        path
          fill color-primary-text

    &.-anchor
      width 100%
      height 100%
      max-width none
      &::after
        top calc(50% - 4px)
        width size-minGrid*2
        height size-minGrid*2
        border-right 2px solid currentColor
        border-bottom 2px solid currentColor
        transform rotate(45deg)
        transform-origin center
        background-color transparent
        border-radius 0

    &.-color-secondary
      background-color color-secondary
      border-color color-secondary
      &:hover
        background-color color-secondary-dark
        border-color color-secondary-dark
        color color-primary-text
        &::after
          background-color color-primary-text
        path
          fill color-primary-text

    +breakpoint(md)
      width clamp(1px,vw(368,1025px),368px)
      font-size 1.4rem
    +breakpoint(sm)
      width 90%
      max-width 90%
      padding size-minGrid*4 size-minGrid*8 size-minGrid*4 size-minGrid*4
      font-size 1.3rem
      &.-pdf
        padding-left size-minGrid*13
        & ^[0]-primary__icon
          right auto
          left size-minGrid*4

  &-pageTop
    &-wrapper
      display flex
      justify-content flex-end
    @extend .c-button-primary
    margin auto 0 auto auto
    width auto
    padding size-minGrid*3 size-minGrid*8 size-minGrid*3  size-minGrid*3
    text-align center
    &::after
      top calc(50% - 2px)
      width size-minGrid*2
      height size-minGrid*2
      border-right 2px solid currentColor
      border-bottom 2px solid currentColor
      transform rotate(-135deg)
      transform-origin center
      background-color transparent
      border-radius 0
    &:hover
      &::after
        transform rotate(-135deg)
        background-color transparent

  &-secondary
    margin auto
    text-align center
    width clamp(1px,vw(550),550px)
    max-width 100%
    line-height 1.3
    &__link
      display block
      padding size-minGrid
      background color-secondary
      color color-primary-text
      box-shadow 0 3px 3px rgba(0, 0, 0, 0.2);
      transition background .2s ease-out
      &:visited
        color color-primary-text
      &:hover
        background-color color-secondary-light
    &__title
      padding size-minGrid*2
      font-size clamp(size-font--medium,vw(17),1.7rem)
      font-weight bold
    &__text
        padding size-minGrid
        margin-top size-minGrid
        background-color #fff
        color color-base-text
        &:visited
          color-base-text
    +breakpoint(sm)
      min-width 0
      width 100%
      &__title
        font-size 1.5rem

