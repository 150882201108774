/* 
  _new_
-----------------------------------------------*/
.c-selectbox-typeA
  position relative
  display block
  width 100%
  font-size 1.6rem
  &::before
  &::after
    content ''
    position absolute
    pointer-events none
  &::before
    content ''
    right 0
    top 0
    bottom 0
    display inline-block
    width size-minGrid*11
    border-radius 0 3px 3px 0
    background-color color-primary
  &::after
    position absolute
    top calc(50% - 1px)
    right 1.4em
    transform translate(50%, -50%) rotate(45deg)
    width 10px
    height 10px
    border-bottom 2px solid #fff
    border-right 2px solid #fff
    content ''
  &__select
    width 100%
    height size-minGrid*11
    appearance none
    -webkit-appearance none
    -moz-appearance none
    padding .4em 3.6em .4em .8em
    border 2px solid color-primary
    border-radius 3px
    background-color #fff
    font-size 1em
    cursor pointer
    &:focus
      outline 1px solid color-primary