/* 
  _new_
-----------------------------------------------*/
propSet = {
  m: 'margin'
  mt: 'margin-top'
  mb: 'margin-bottom'
  mr: 'margin-right'
  ml: 'margin-left'
  mx: 'margin-right' 'margin-left'
  my: 'margin-top' 'margin-bottom'
}

sizes = {
  xs: 1
  s: 2
  m: 3
  l: 4
  xl: 6
  xxl: 8
  xxxl: 10
}

for key, props in propSet
  for name, size in sizes
    .u-{key}-{name}
      for prop in props
        {prop} size-minGrid*size

.u-mt-none
  margin-top 0 !important

.u-mb-none
  margin-bottom 0 !important

.u-m-none
  margin 0 !important

.u-m-none-sm
  +breakpoint(sm)
    margin 0 !important

.u-m-none-upperSm
  +breakpoint(sm,min)
    margin 0 !important