/* 
  _new_
-----------------------------------------------*/
.c-breadcrumb
  display flex
  flex-wrap wrap
  gap 0 size-minGrid*2
  color color-primary-text
  font-size 1.2em
  margin 0
  padding 0 size-minGrid*7.5
  list-style none
  word-break break-all
  &__list
    display flex
    align-items center
    gap size-minGrid*2
    & + &::before
      content ""
      display flex
      justify-content center
      align-items center
      width size-minGrid
      height size-minGrid
      background-color color-primary-text
      border-radius 100%
      opacity 0.6
  &__list:first-child
    & ^[0]__label
      text-transform uppercase
  &__link
    &, &:visited
      color inherit
      opacity 0.6
      background-color transparent
      text-decoration none
  &.-filledColorContentsBackground
    padding size-minGrid*2 size-minGrid*7.5
    background-color color-contentsBackground
    color color-base-text
    font-weight 700
    & ^[0]__list
      & + ^[0]__list::before
        background-color color-base-text
    & ^[0]__link
      &:hover
        text-decoration underline

/* 
  _old_
-----------------------------------------------*/
// is-ellipsisを-ellipsisに変更予定
.c-breadcrumb
  display flex
  flex-wrap wrap
  gap 0 size-minGrid*2
  color color-primary-text
  font-size 1.2em
  margin 0
  padding 0 size-minGrid*7.5
  list-style none
  word-break break-all
  &__list
    display flex
    align-items center
    gap size-minGrid*2
    & + &::before
      content ""
      display flex
      justify-content center
      align-items center
      width size-minGrid
      height size-minGrid
      background-color color-primary-text
      border-radius 100%
      opacity 0.6
  &__list:first-child
    & ^[0]__label
      text-transform uppercase
  &__link
    &, &:visited
      color inherit
      opacity 0.6
      background-color transparent
      text-decoration none
  &.is-ellipsis
    flex-wrap nowrap
    & ^[0]__label
      max-width 20em
      overflow hidden
      text-overflow ellipsis
      white-space nowrap