/*
  _new_
-----------------------------------------------*/
.c-paragraph-typeA
  verticalLayout(size-minGrid)
  font-weight 700
  line-height 2
  font-size size-font--large
  &__emphasis
    color color-primary
  +breakpoint(sm)
    font-size 1.6rem

.c-paragraph-typeB
  color color-primary
  font-size size-font--xxlarge
  font-weight 700
  +breakpoint(sm)
    font-size size-font--large

.c-paragraph-typeC
  font-weight 700
  font-size 1.6rem
  +breakpoint(sm)
    font-size size-font--medium