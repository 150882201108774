*
  word-break break-all

html
  font-family sans-serif
  font-size 62.5%
  scroll-behavior smooth
  scroll-padding-top 80px
  +breakpoint(smd)
    scroll-padding-top 65px

body
  background color-bodyBackground
  font-size size-font--base
  color color-base-text
  line-height size-lineHight--base
  font-family "Lato", "Zen Kaku Gothic New", sans-serif;
  &.-bg-dark
    background-color color-bodyBackground-dark
  +breakpoint(sm)
    font-size 1.3rem

img
  max-width 100%

em
  font-style normal

hr
  color color-border

[v-cloak]
  display none