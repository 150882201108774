/* 
  _new_
-----------------------------------------------*/
.l-container
  display flex
  flex-direction column
  gap size-minGrid*6
  > *:first-child,
  > *:first-child > *:first-child
    margin-top 0
  &.-gapXsmall
    gap size-minGrid*3
  &.-gapSmall
    gap size-minGrid*5
  &.-gapMedium
    gap size-minGrid*8
  &.-gapLarge
    gap size-minGrid*10
  &.-gapXlarge
    gap size-minGrid*15
  &.-gapXxlarge
    gap size-minGrid*20
  &.-horizontalCenter
    align-items center

  +breakpoint(sm)
    gap size-minGrid*5
    &.-gapXxsmall
      gap size-minGrid*2
    &.-gapXsmall
      gap size-minGrid*3
    &.-gapSmall
      gap size-minGrid*4
    &.-gapMedium
      gap size-minGrid*7
    &.-gapLarge
      gap size-minGrid*8
    &.-gapXlarge
      gap size-minGrid*9
    &.-gapXxlarge
      gap size-minGrid*10


