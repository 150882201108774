/*
  _new_
--------------------------------------*/
.l-contentsArea
  width 100%
  max-width size-contentsArea
  margin 0 auto
  padding-left size-minGrid*10
  padding-right size-minGrid*10
  +breakpoint(sm)
    padding-left size-minGrid*5
    padding-right size-minGrid*5
  &.-narrow
    max-width size-contentsArea-narrow
    padding-left size-minGrid*8
    padding-right size-minGrid*8