.c-logoAndText
  display grid
  grid-template-columns 1fr 2fr
  gap size-minGrid*5
  &__logo
    display flex
    justify-content center
    align-items center
    &.-bordered
      border 1px solid color-border
      padding size-minGrid*2 size-minGrid*6
      aspect-ratio 3 / 1
    &__image
      display block
      object-fit contain
  +breakpoint(sm)
    grid-template-columns 1fr