/* 
  _old_
-----------------------------------------------*/
// l-container,l-columnに変更

@import "column"

.l-layoutContainer
  display flex
  flex-direction column
  gap size-minGrid*8
  > *:first-child,
  > *:first-child > *:first-child
    margin-top 0
  
  &.-center
    justify-content center
    align-items center
  &.-alignCenter
    align-items center
  &.-top
    align-items start
  &.-bottom
    align-items end
  &.-start
    justify-content flex-start
  &.-end
    justify-content flex-end
  &.-between
    flex-direction row
    justify-content space-between
  &.-row
    flex-direction row
  
  &.-imgAlignment
    & ^[0]__content
      gap size-minGrid*5
      display flex
      flex-direction column
      > *:first-child,
      > *:first-child > *:first-child
        margin-top 0
      > section
        margin-top size-minGrid*3
  
  +breakpoint(sm, min)
    &.-imgAlignment
      flex-direction row
      justify-content flex-start
      align-items start
      grid-column-gap 4%
      & ^[0]__img
        flex-shrink 0
        max-width 50%
        &.-img-large
          max-width 60%
      & ^[0]__content
        flex-grow 1
        flex-shrink 1
        flex-basis auto
      &.-reverse
        flex-direction row-reverse
      &.-alignCenter
        align-items center

  +breakpoint(sm)
    verticalLayout(size-minGrid*6)
    justify-content flex-start
    align-items normal
    &.-between, &.-row
      flex-direction column
      justify-content flex-start
      align-items normal
    &.-imgAlignment
      & ^[0]__img
        text-align center
        order 1
      & ^[0]__content
        width 100%
        order 2
    &.-sm-reverse
      flex-direction column-reverse