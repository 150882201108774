/* 
  _old_
-----------------------------------------------*/
// c-list-bulletに変更
.c-markerList
  verticalLayout(size-minGrid)
  &__item
    padding-left 1em
    position relative
    &::before
      content "";
      width em(6);
      height em(6);
      background-color color-secondary
      border-radius 50%;
      position absolute;
      top 0.8em;
      left 0;
    & > ^[0]
      margin-top size-minGrid
      & ^[0]__item
        position relative
        padding-left 1em
        &:not(:first-child)
          margin-top size-minGrid
        &::before
          transform rotate(45deg)
          background-color color-base-text
          border-radius 0
  &.-small
    font-size size-font--small
    & ^[0]__item
      &::before
        width .4rem
        height .4rem
  &.-check
    gap size-minGrid*2
    & ^[0]__item
      padding-left 1.4em
      &::before
        top 0.5em;
        width em(14)
        height em(12)
        background-image url('../img/_common/icon_check.svg')
        background-repeat no-repeat
        background-size contain
        background-color transparent
  +breakpoint(sm)
    &__item
      &::before
        width .5rem
        height .5rem
