/*
  _new_
-----------------------------------------------*/
.c-banner-fixed
  position fixed
  right 0
  bottom 0
  z-index 99
  &__button
    display block
    width 40px
    height 40px
    margin-left auto
    background-color color-primary
    position absolute
    right 0
    bottom 100%
    cursor pointer
    transition background-color 0.3s ease-out 
    &::before
    &::after
      display block
      content ''
      width 4px
      height 24px
      background-color #fff
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      margin auto
    &::before
      transform rotateZ(45deg)
    &::after
      transform rotateZ(-45deg)
    &:hover
      background-color color-primary-light
  &__link
    display block
    width 320px
    margin auto
    background-color #fff
    transition opacity 0.3s ease-out 
    &:hover
      opacity 0.8

  &.is-close
    & ^[0]__button::before
      width 24px
      height 4px
      transform rotateZ(135deg)
    & ^[0]__button::after
      width 10px
      height 10px
      background-color transparent
      border 10px solid transparent
      border-left-color #fff
      margin 0
      box-sizing border-box
      bottom auto
      left auto
    & ^[0]__link
      display none

  +breakpoint(sm)
    display none

