/* 
  _old_
-----------------------------------------------*/
// c-area-filledに変更
.c-contentsArea
  padding size-minGrid*30 0
  &.-dark
    background-color color-contentsBackground
  &.-light
    background-color color-blue-light
  &.-pb-none
    padding-bottom 0
  &.-over-hidden
    overflow hidden
  +breakpoint(sm)
    padding size-minGrid*15 0