/* 
  _new_
-----------------------------------------------*/
.c-radio-typeA
  display none
  &__label
    display inline-block
    padding size-minGrid*2 size-minGrid*5 6px
    border 1px solid color-primary
    border-radius 3rem
    background-color #fff
    font-size 1.3rem
    font-weight 700
    color color-primary
    line-height 1.5
    transition all .3s ease-out
    &:hover
      background-color color-primary
      color #fff
  &:checked + .c-radio-typeA__label
    background-color color-primary
    color #fff
  +breakpoint(sm)
    &__label
      padding size-minGrid size-minGrid*3
      font-size 1.2rem
