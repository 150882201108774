/* 
  _new_
-----------------------------------------------*/
.c-definitionList
  verticalLayout(size-minGrid*2)

.c-definitionList-typeA
  display grid
  grid-template-columns auto 1fr
  gap size-minGrid*2 0
  width 100%
  line-height 1.8
  &__term
    display flex
    align-items center
    justify-content center
    color color-primary-text
    background-color color-primary
    padding size-minGrid*2 size-minGrid*4
  &__description
    border solid 1px color-primary
    padding size-minGrid*2 size-minGrid*6  
  +breakpoint(sm)
    grid-template-columns 1fr
    gap 0
    &__term:not(:first-child)
      margin-top size-minGrid*2
  &.-colorSecondary
    & ^[0]__term
      background-color color-secondary
    & ^[0]__description
      border-color color-secondary