/* 
  _new_
-----------------------------------------------*/
.l-wrapper
  position relative
  padding-top size-minGrid*30
  padding-bottom size-minGrid*30
  verticalLayout(size-minGrid*20)
  +breakpoint(sm)
    padding-top size-minGrid*15
    padding-bottom size-minGrid*30
    gap size-minGrid*10
