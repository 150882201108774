/*
  _new_
-----------------------------------------------*/
.c-courseInfo-typeA
  verticalLayout(size-minGrid*4)
  &__header
    display flex
    flex-wrap wrap
    align-items center
    gap size-minGrid*2 size-minGrid*4
  &__datetime
    display flex
    flex-wrap wrap
    align-items center
    gap 0 size-minGrid*2
    line-height 1.5
  &__date
    display block
    font-size size-font--xxlarge
    color color-primary
    font-weight 700
  &__time
    display block
    padding-top 0.1em
    font-size 1.6rem
    font-family font-lato
    color color-gray-dark
  &__thumbnail
    border 1px solid color-border-light
    border-radius 5px
    overflow hidden
    img
      width 100%
      height auto
  &__title
    font-size 1.6rem
    font-weight 700
    line-height 1.5
  &__button
    margin-top auto
    max-width 100%
  &__notice
    margin-top auto
    text-align center
  &.-modifierA
    padding size-minGrid*5
    background #fff
    gap size-minGrid*3
    & ^[0]__thumbnail
      border 0
    & ^[0]__date
      font-size size-font--xlarge
    & ^[0]__time
      font-size size-font--medium
    & ^[0]__title
      font-weight normal
      display -webkit-box
      -webkit-box-orient vertical
      -webkit-line-clamp 3
      overflow hidden
      font-size size-font--medium
    & ^[0]__button
      &__link
        width 80%
  +breakpoint(md)
    &__title
      font-size size-font--medium
  +breakpoint(sm)
    &__item
      gap size-minGrid*3
    &__header
      gap size-minGrid*2
    &__date
      font-size size-font--xlarge
    &__time
      font-size 1.4rem
    &__title
      font-size size-font--medium
    &__button
      margin-top size-minGrid*2


