/* 
  _new_
-----------------------------------------------*/
.c-navigation-typeA
  &__list
    display grid
    grid-template-columns repeat(6,1fr)
    gap 0 size-minGrid
    align-items end
  &__heading
    position relative
    padding size-minGrid*2
    display flex
    flex-direction column
    align-items center
    justify-content center
    text-align center
    width 100%
    height 80px
    background-color #fff
    font-weight 700
    font-size clamp(1.3rem,vw(15),size-font--medium)
    line-height 1.5
    &::after
      content: ''
      position absolute
      display block
    &.is-active
      background-color color-primary
      color #fff
      height 90px
      & ^[0]__heading__icon
        path
          fill #fff
    for functionTabColors in (
      'construction'\
      'store'\
      'customer'\
      'promotion'\
      'o2o'\
      'tieup'
      )
      &.-{functionTabColors}
        &.is-active
          background-color function-color- + functionTabColors
    &__icon
      display block
      margin-bottom size-minGrid*2
      height 26px
  &__panel
    &__content
      display none
      border-top 3px solid
      padding size-minGrid*6 size-minGrid*8 size-minGrid*8
      background-color #fff
      > *:first-child,
      > *:first-child > *:first-child
        margin-top 0
      &.is-show
        verticalLayout(size-minGrid*6)
      for functionItemColors in (
        'construction'\
        'store'\
        'customer'\
        'promotion'\
        'o2o'\
        'tieup'
        )
        &.-{functionItemColors}
          border-color function-color- + functionItemColors
          .c-button-typeA:hover
            background-color rgba(function-color- + functionItemColors,.1)
            border-left 4px solid function-color- + functionItemColors
          & ^[0]__panel__item.is-current
            .c-button-typeA
              background-color rgba(function-color- + functionItemColors,.1)
              border-left 4px solid function-color- + functionItemColors
    &__list
      display grid
      grid-template-columns repeat(auto-fit, minmax(240px, 1fr));
      gap size-minGrid size-minGrid*4
    &__item
      display flex
      min-height 50px
      line-height 1.5


.c-navigation-typeB
  summary::-webkit-details-marker
    display:none;
  &__inner
    padding 0
    verticalLayout(size-minGrid)
  &__heading
    list-style none
    flex-direction row
    justify-content flex-start
    height auto
    padding size-minGrid*3
    text-align left
    background-color #fff
    font-weight 700
    &__icon
      display inline-block
      margin-right size-minGrid
      width 50px
      text-align center
      svg
        width auto
  &__details[open]
    color #fff
    & ^[0]__heading__icon
      path
        fill #fff
  for functionToggleColors in (
    'construction'\
    'store'\
    'customer'\
    'promotion'\
    'o2o'\
    'tieup'
    )
    & ^[0]__details.-{functionToggleColors}[open]
      & ^[0]__heading
        background-color function-color- + functionToggleColors
  &__panel
    overflow hidden
    &__content
      verticalLayout(size-minGrid*5)
      padding size-minGrid*4 size-minGrid*2
      background-color #fff
      for functionItemColors in (
        'construction'\
        'store'\
        'customer'\
        'promotion'\
        'o2o'\
        'tieup'
        )
        &.-{functionItemColors}
          border-color function-color- + functionItemColors
          .c-button-typeA:hover
            background-color rgba(function-color- + functionItemColors,.1)
            border-left 4px solid function-color- + functionItemColors
          & ^[0]__panel__item.is-current
            .c-button-typeA
              background-color rgba(function-color- + functionItemColors,.1)
              border-left 4px solid function-color- + functionItemColors
    &__list
      display grid
      grid-template-columns repeat(2,1fr)
      gap  size-minGrid size-minGrid*2
    &__item
      display flex
      min-height 50px
      line-height 1.5


.c-navigation-typeC
    display flex
    align-items center
    gap size-minGrid*6
    flex-wrap wrap
    padding size-minGrid*5
    background-color color-contentsPointBackground
    border solid 1px color-blue-light
    border-radius size-minGrid
    &__heading
      font-weight bold
      font-size size-font--base
      flex-grow 1
    &__items
      display flex
      flex-wrap wrap
      flex-grow 2
      gap size-minGrid*3
    &__link
      display flex
      align-items center
      justify-content center
      width 100%
      height 100%
      font-weight bold
      color color-primary
      background-color color-button-light
      border 1px solid color-primary
      border-radius size-minGrid*3
      padding  2px size-minGrid*4
      line-height 1
      transition all .2s ease-out
      cursor pointer
      &::after
        display inline-block
        content ''
        background-image url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%276%27%20height%3D%2710%27%20viewBox%3D%270%200%206%2010%27%3E%3Cpath%20d%3D%27M-11214.922-5244a1%2C1%2C0%2C0%2C1-.707-.293%2C1%2C1%2C0%2C0%2C1%2C0-1.414l3.293-3.293-3.293-3.293a1%2C1%2C0%2C0%2C1%2C0-1.414%2C1%2C1%2C0%2C0%2C1%2C1.414%2C0l4%2C4a1%2C1%2C0%2C0%2C1%2C0%2C1.414l-4%2C4A1%2C1%2C0%2C0%2C1-11214.922-5244Z%27%20transform%3D%27translate%2811215.922%205254%29%27%20fill%3D%27%23014BA5%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E")
        background-repeat no-repeat
        background-position center right
        background-size contain
        width 0.6em
        height 0.6em
        margin-left 0.2em
      &:visited
        color color-primary
      &.is-current
      &:hover
        background-color color-primary
        color color-primary-text
        &::after
          background-image url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%276%27%20height%3D%2710%27%20viewBox%3D%270%200%206%2010%27%3E%3Cpath%20d%3D%27M-11214.922-5244a1%2C1%2C0%2C0%2C1-.707-.293%2C1%2C1%2C0%2C0%2C1%2C0-1.414l3.293-3.293-3.293-3.293a1%2C1%2C0%2C0%2C1%2C0-1.414%2C1%2C1%2C0%2C0%2C1%2C1.414%2C0l4%2C4a1%2C1%2C0%2C0%2C1%2C0%2C1.414l-4%2C4A1%2C1%2C0%2C0%2C1-11214.922-5244Z%27%20transform%3D%27translate%2811215.922%205254%29%27%20fill%3D%27%23fff%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E")

      
