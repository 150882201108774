/* 
  _old_
-----------------------------------------------*/
// c-list-notificationに変更
.c-notificationList
  verticalLayout(size-minGrid)
  line-height 1.5
  &__item
    padding-left 1.2em
    position relative
    color #666
    &::before
      display block
      content "※"
      position absolute
      top 0
      left 0
      margin-right 0.2em
  &.-marked
    counter-reset notification
    & ^[0]__item
      padding-left 2.2em
      counter-increment notification
      &::before
        content "※" counter(notification)
        margin-right 0.5em
  &.-var2
    & ^[0]__item
      &::before
        content "＊"
  &.-center
    text-align center
    & ^[0]__item
      padding-left 0
      &::before
        position relative
        display inline-block
  &.-inlineCenter
    display inline-block
    & ^[0]__item
      text-align left
      &:nth-child(n+2)
        margin-top size-minGrid

  +breakpoint(sm, min)
    &.-end
      text-align right
      & ^[0]__item
        &::before
          position relative
          display inline-block
