
/* 
  _new_
-----------------------------------------------*/
.c-tel-typeA
  line-height 1
  &__link
    color color-primary
    position relative
    display block
    font-weight bold
    padding-left 4em
    &:visited
      color color-primary
    &::before
      position absolute
      top 0.2em
      left 0
      content ''
      display inline-block
      background color-primary
      background-image url('data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22design%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228.85%22%20height%3D%2241.61%22%20viewBox%3D%220%200%2028.85%2041.61%22%3E%20%3Cpath%20id%3D%22phone-3-icon%22%20d%3D%22m15.51%2C11.22l-3.45%2C1.73L6.42%2C1.73l3.45-1.73%2C5.63%2C11.22Zm7.71%2C15.36l-3.45%2C1.73%2C5.63%2C11.22%2C3.45-1.73-5.63-11.22Zm-13.17-12.41L4.33%2C2.77c-2.32%2C1.24-8.86%2C4.11.76%2C23.28%2C9.67%2C19.26%2C15.66%2C15.7%2C18.21%2C14.51l-5.71-11.38c-3.67%2C1.82-11.15-13.08-7.55-15.03Z%22%20style%3D%22fill%3A%20%23fff%3B%22%2F%3E%3C%2Fsvg%3E')
      background-repeat no-repeat
      background-size 2.3rem 3.3rem
      background-position center center
      width 4.6rem
      height 4.6rem
      border-radius 50%
  &__num
    font-size 4.8rem
  &__small
    display block
    margin-top size-minGrid
    font-weight normal
  &.-textColorSecondary
    & ^[0]__link
      color color-secondary
      &:visited
        color color-secondary
      &::before
        background-color color-secondary

  +breakpoint(sm,min)
    &__link
      pointer-events none
  +breakpoint(md)
    &__link
      padding-left 2.8em
      &::before
        width 3.2rem
        height 3.2rem
        background-size 1.5rem 2.2rem
    &__num
      font-size 3.6rem



/* 
  _old_
-----------------------------------------------*/
.c-tel
  line-height 1
  &__link
    color color-primary
    position relative
    display block
    font-weight bold
    padding-left 4em
    &:visited
      color color-primary
    &::before
      position absolute
      top 0.2em
      left 0
      content ''
      display inline-block
      background color-primary
      background-image url('../img/_common/icon_tel_white.svg')
      background-repeat no-repeat
      background-size 2.3rem 3.3rem
      background-position center center
      width 4.6rem
      height 4.6rem
      border-radius 50%
  &__num
    font-size 4.8rem
  &__small
    display block
    margin-top size-minGrid
    font-weight normal
  
  &.-secondary
    & ^[0]__link
      color color-secondary
      &:visited
        color color-secondary
      &::before
        background-color color-secondary

  +breakpoint(sm,min)
    &__link
      pointer-events none
  +breakpoint(md)
    &__link
      padding-left 2.8em
      &::before
        width 3.2rem
        height 3.2rem
        background-size 1.5rem 2.2rem
    &__num
      font-size 3.6rem