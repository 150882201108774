/* 
  _old_
-----------------------------------------------*/
.p-tableSet
  verticalLayout(size-minGrid*4)
  &__afterContent
    verticalLayout(size-minGrid*4)
  +breakpoint(md)
    &__table
      &.-scroll
        overflow scroll
        padding-bottom size-minGrid*3
        table
          min-width 800px