/* 
  _old_
-----------------------------------------------*/
// 削除予定
sizes = {
  xxs: size-font--xxsmall
  xs: size-font--xsmall
  s: size-font--small
  m: size-font--medium
  l: size-font--large
  xl: size-font--xlarge
  xxl: size-font--xxlarge
  xxxl: size-font--xxxlarge
  xxxxl: size-font--xxxxlarge
}

  for name, size in sizes
    .u-fs-{name}
      font-size size