.c-function-heading-label
  position relative
  padding-bottom 4px
  display flex
  align-items center
  font-weight bold
  z-index 1
  line-height 1.5
  &__label
    position relative
    display flex
    flex-direction column
    align-items center
    justify-content center
    min-width size-minGrid*16
    width size-minGrid*16
    height size-minGrid*16
    background-color color-primary
    color #fff
    text-align center
    line-height 1.1
    font-size size-font--small
    &::after
      content: ''
      position absolute
      top 4px
      left 4px
      width 100%
      height 100%
      background-color color-secondary
      z-index -1
  &__num
    display block
    font-size size-font--xxxxlarge
  &__text
    padding-left size-minGrid*5
    font-size 2.2rem

  +breakpoint(sm)
    padding-bottom 4px
    &__label
      min-width size-minGrid*11
      width size-minGrid*11
      height size-minGrid*11
      font-size size-font--xsmall
      &::after
        top 4px
        left 4px
    &__num
      font-size size-font--large
    &__text
      padding-left size-minGrid*5
      font-size 1.6rem

.c-function-heading-underLine
  padding-bottom size-minGrid
  font-weight bold
  line-height 1.5
  font-size size-font--large
  border-bottom 1px solid color-border
  +breakpoint(sm)
    font-size 1.6rem

.c-function-heading-frame
  padding size-minGrid*3 size-minGrid*4
  font-weight bold
  line-height 1.5
  color color-primary
  border 3px solid
  font-size size-font--xlarge
  &.-large
    font-size clamp(2.2rem,vw(28),2.8rem)
  +breakpoint(sm)
    padding size-minGrid*2 size-minGrid*3
    font-size 1.6rem
    &.-large
      font-size size-font--large

.c-function-heading-hasSmall
  font-weight bold
  line-height 1.5
  &__small
    font-size size-font--small
    display block
  &__title
    font-size clamp(1.4rem,vw(16),1.6rem)