/* 
  _new_
-----------------------------------------------*/
.c-section-typeA
  overflow hidden
  border 1px solid color-border
  border-radius size-minGrid
  &__body
    verticalLayout(size-minGrid*10)
    padding size-minGrid*10
  +breakpoint(sm)
    &__body
      gap size-minGrid*6
      padding size-minGrid*5
