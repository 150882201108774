/*
  _new_
-----------------------------------------------*/
.c-divider-typeA
  position relative
  border 0
  &::before
    content ''
    background-color color-primary
    width 85px
    height 3px
    display block
    position absolute
    left 50%
    top 0
    transform translateX(-50%)



/*
  _old_
-----------------------------------------------*/
.c-divider
  height 0
  border 1px solid color-contentsPointBorder