/* 
  _old_
-----------------------------------------------*/
.p-imageTextSet
  display grid
  grid-template-columns 56% 1fr
  gap size-minGrid*5 4%
  &__label
    margin-bottom size-minGrid*3
  &__content
    &__heading
      margin-bottom size-minGrid*4
      line-height 1.5
      font-size 2.7rem
      font-weight bold
    &__link
      margin-top size-minGrid*10
  &__image
    &__link
      display block
      overflow hidden
      border-radius size-minGrid*1.5
      img
        transition 0.5s ease-in-out
      &:hover
        img
          transform scale(1.05)
          transform-origin center
  &.-reverse
    grid-template-columns 1fr 56%
    & ^[0]__image
      order 1

  +breakpoint(md)
    grid-template-columns 1fr
    &__image
      text-align center
    &.-reverse 
      grid-template-columns 1fr

    +breakpoint(sm)
      &__content
        &__heading
          font-size size-font--large
        &__link
          margin-top size-minGrid*5