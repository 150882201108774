/*
  _new_
-----------------------------------------------*/
.c-area-filled
  padding size-minGrid*30 0
  verticalLayout(size-minGrid*20)
  &.-pdSmall
    padding size-minGrid*20 0
  &.-filledColorContentsBackground
    background-color color-contentsBackground
  &.-filledColorLight
    background-color color-blue-light

  +breakpoint(sm)
    padding size-minGrid*15 0
    gap size-minGrid*10
    &.-pdSmall
      padding size-minGrid*10 0
