/* 
  _new_
-----------------------------------------------*/
.c-orderedList
  verticalLayout(size-minGrid*0)
  counter-reset orderedListItem
  &__item
    padding-left 2em
    position relative
    &::before
      counter-increment orderedListItem
      content counter(orderedListItem)'.'
      position absolute
      top 0
      left 0
      text-align right
      width 1.5em
    & > ^[0]
      margin size-minGrid*2 0
      & ^[0]__item
        padding-left 4em
        &::before
          content counters(orderedListItem,'-')'.'
          width 3.5em
        & ^[0]__item
          padding-left 5em
          &::before
            width 4.5em
    & > *
      padding initial


.c-orderedList-typeA
  verticalLayout(size-minGrid*1.5)
  counter-reset orderedListItem
  list-style-type decimal
  padding-left 1.5em
  &__item
    & > ^[0]
      list-style-type lower-roman
      margin-top size-minGrid


.c-orderedList-typeB
  verticalLayout(size-minGrid)
  counter-reset orderedListItem
  & ^[0]__item
    position relative
    padding-left 1.8em
    &::before
      counter-increment orderedListItem
      content counter(orderedListItem)
      display flex
      justify-content center
      align-items center
      padding size-minGrid
      width size-minGrid*4
      height size-minGrid*4
      font-size 0.8em
      border 1px solid currentColor
      border-radius 50%
      position absolute
      top 0.5em
      left 0

.c-orderedList-typeC
  verticalLayout(size-minGrid)
  counter-reset orderedListItem
  &__item
    display flex
    align-items center
    gap size-minGrid*3
    border solid 1px color-border
    padding  size-minGrid*3
    background-color color-bodyBackground
    font-size size-font--base
    &::before
      counter-increment orderedListItem
      content counter(orderedListItem)
      display flex
      flex-shrink 0
      justify-content center
      align-items center
      width size-minGrid*8
      height size-minGrid*8
      border 1px solid color-gray-dark
      border-radius 50%
      font-family font-lato


.c-orderedList-step
  verticalLayout(size-minGrid*8)
  counter-reset orderedListItem
  &__content 
    flex-grow 1
    verticalLayout(size-minGrid*8)
  &__item
    display flex
    gap size-minGrid*7
    counter-increment step 1
    position relative
    margin-bottom size-minGrid*3
    &::before
      display flex
      flex-shrink 0
      justify-content center
      align-items center
      content "STEP " counter(step)
      color color-primary
      background-color color-blue-light
      padding size-minGrid*5
      border-radius 2px
    &::after
      content ""
      display block
      width 0
      height 0
      border-left solid 44px transparent
      border-right solid 44px transparent
      border-top solid 12px color-blue-light
      position absolute
      left 0
      bottom -12px
    &:last-of-type
      margin-bottom 0
      &::after
        content none  
  +breakpoint(md)
    &__item
      flex-direction column
      gap size-minGrid*3
      &::before
        display inline-block
        padding 0 size-minGrid*2
        font-size size-font--small
        width fit-content
        font-family font-latp
        font-weight bold
      &::after
        content none

.c-orderedList-alphabet
  list-style-type lower-latin
  padding-left 1.5em
  verticalLayout(size-minGrid*0)
  &.-textSmall
    font-size size-font--small
  &.-uppercase
    list-style-type upper-latin


/* 
  _old_
-----------------------------------------------*/
.c-orderedList
  verticalLayout(size-minGrid*0)
  counter-reset orderedListItem
  &__item
    padding-left 2em
    position relative
    &::before
      counter-increment orderedListItem
      content counter(orderedListItem)'.'
      position absolute
      top 0
      left 0
      text-align right
      width 1.5em
    & > ^[0]
      margin size-minGrid*2 0
      & ^[0]__item
        padding-left 4em
        &::before
          content counters(orderedListItem,'-')'.'
          width 3.5em
        & ^[0]__item
          padding-left 5em
          &::before
            width 4.5em
    & > *
      padding initial
  &.-bg
    line-height 1.8
    gap size-minGrid*2
    & ^[0]__item
      padding-left 2.5em
      &::before
        content counter(orderedListItem)
        display flex
        align-items center
        justify-content center
        text-align center
        color #fff
        background color-primary
        width 1.8em
        height 1.8em
  &.-digit3
    & ^[0]__item
      padding-left 3.5em
      &::before
        width 3em
  &.-color-secondary
    & ^[0]__item
      &::before
        color color-secondary
        font-weight bold
        font-family 'Lato', sans-serif;
  &.-child-roman
    & ^[0]__item
      & + ^[0]__item
        margin-top  6px
      & > ^[0]
        & ^[0]__item
          padding-left 2em
          &::before
            content counter(orderedListItem, lower-roman)'.'
            width auto
            text-align right
            width 1.5em