@import "../function/function-heading"

.c-orderedContent
  verticalLayout(size-minGrid*9)
  &__heading
    @extend .c-heading-num
    margin-bottom 0
  &__item
    verticalLayout(size-minGrid*5)

  +breakpoint(sm)
    verticalLayout(size-minGrid*5)