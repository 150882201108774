/* 
  _new_
-----------------------------------------------*/
.c-image-typeA
  border 1px solid color-border
  padding size-minGrid*2
  width 200px
  &__image
    width: 100%
    object-fit cover
    aspect-ratio 20 / 23


