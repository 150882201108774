/* 
  _new_
-----------------------------------------------*/
.u-text-start
  text-align left !important
.u-text-center
  text-align center !important
.u-text-end
  text-align right !important

.u-text-nowrap
  white-space nowrap !important

.u-text-smaller
  font-size 0.8em !important
.u-text-larger
  font-size 1.2em !important

.u-text-weight-normal
  font-weight normal !important

.u-text-uppercase
  text-transform uppercase !important



/* 
  _old_
-----------------------------------------------*/
.u-text-center-sm
  +breakpoint(sm)
    text-align center !important