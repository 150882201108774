/*
  _new_
-----------------------------------------------*/
.c-contentsSubSection
  verticalLayout(size-minGrid*8)
  &__body
    verticalLayout(size-minGrid*8)
    &.-gapSmall
      gap size-minGrid*6  
    &.-gapMedium
      gap size-minGrid*10
    &.-gapLarge
      gap size-minGrid*15

  &.-gapXsmall
    gap size-minGrid*5
  &.-gapSmall
    gap size-minGrid*6

  +breakpoint(sm)
    gap size-minGrid*5
    &__body
      gap size-minGrid*6
      &.-gapSmall
        gap size-minGrid*4
      &.-gapMedium
        gap size-minGrid*7
      &.-gapLarge
        gap size-minGrid*9

    &.-gapXsmall
      gap size-minGrid*4
    &.-gapSmall
      gap size-minGrid*4