/* 
  _new_
-----------------------------------------------*/
.c-label-rounded
  display inline-block
  border 1px solid currentColor
  color color-primary
  padding 6px size-minGrid*3 4px
  line-height 1.2
  font-size 1.4rem
  font-weight 700
  border-radius 3em
  &.-textSmall
    font-size 1rem
    padding 2px size-minGrid*2
    color color-primary
    line-height 1.5
    font-weight 700
  &.-filledColorPrimary
    background-color color-primary
    border-color color-primary
    color #fff
  &.-filledColorOmni
    border-color color-omni
    background-color color-omni
    color #fff
  &.-filledColorSoy
    color color-base-text
    border-color color-soy
    background-color color-soy
  +breakpoint(sm)
    font-size 1.3rem
    padding 4px size-minGrid*2 3px


.c-label-typeA
  display inline-block
  border 2px solid color-green
  border-radius 4px
  color color-green
  padding 6px size-minGrid*3 4px
  line-height 1.2
  font-size 1.4rem
  font-weight 500
  font-size size-font--small
  &.-filledColorAcademy
    background-color color-green
    color #fff
  +breakpoint(sm)
    font-size 1.3rem
    padding 4px size-minGrid*2 3px


.c-label-square
  display inline-block
  border 1px solid currentColor
  color color-primary
  padding 6px size-minGrid*3 4px
  line-height 1.2
  font-size 1.4rem
  font-weight 700
  &.-textSmall
    font-size 1rem
    padding 2px size-minGrid*2
    color color-primary
    line-height 1.5
    font-weight 700
  &.-colorSecondary
    color color-secondary
    border-color color-secondary
  &.-filledColorPrimary
    background-color color-primary
    border-color color-primary
    color #fff
  &.-filledColorSecondary
    border-color color-secondary
    background-color color-secondary
    color #fff
  &.-modifierA
    display inline-block
    font-size 1.3rem
    padding 3px size-minGrid*2 2px
    border 1px solid color-base-text
    color color-base-text
    line-height 1.5
    font-weight 500
  +breakpoint(sm)
    font-size 1.3rem
    padding 4px size-minGrid*2 3px


/* 
  _old_
-----------------------------------------------*/
.c-label
  display inline-block
  padding 0 1em
  border-radius 1em
  letter-spacing 0.1em
  &.-primary
    background-color color-primary
    color color-primary-text
  &.-secondary
    background-color color-secondary
    color color-primary-text
  &.-disabled
    background-color color-gray-dark
    color color-primary-text
  &.-plain
    border 1px solid currentColor

  &-rectangle
    display inline-block
    padding 0 1em
    letter-spacing 0.1em
    &.-secondary
      background-color color-bodyBackground
      border 1px solid color-secondary
      color color-secondary
      padding size-minGrid size-minGrid*3
    &.-bg-secondary
      background-color color-secondary
      color #fff
      padding 0.3em 0.5em 0.2em
      font-size 1.3rem
      font-weight normal
    &.-primary
      border 1px solid color-primary
      color color-primary