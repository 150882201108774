/* 
  _new_
-----------------------------------------------*/
.c-card-typeA
  display grid
  grid-template-columns 1fr
  gap size-minGrid*5
  &__image
    display block
    overflow hidden
    border-radius size-minGrid*1.5
    img
      object-fit cover
      aspect-ratio 16 / 9
      width 100%
      transition 0.5s ease-in-out
      :where(a) &:hover
        transform scale(1.05)
  &__text
    display grid
    grid-template-columns 1fr
    gap size-minGrid*3
  &__heading
    font-size size-font--large
    font-weight bold
    color color-base-text

.c-card-typeB
  verticalLayout(size-minGrid*3)
  background-color color-blue-light
  padding size-minGrid*5
  border-radius 5px
  &__img
    text-align center
  &__content
    verticalLayout(size-minGrid*2)
  &__title
    font-size clamp(1.7rem,vw(20),size-font--xlarge)
    font-weight bold
    line-height 1.5
  +breakpoint(sm,min)
    &__header
      display flex
      align-items center
    &__img
      min-width 120px
      margin-right size-minGrid*7
  +breakpoint(sm)
    &__header
      flex-direction column-reverse
    &__title
      text-align center




/* 
  _old_
-----------------------------------------------*/
.c-card
  display grid
  grid-template-columns 1fr
  gap size-minGrid*5
  &__image
    display block
    overflow hidden
    border-radius size-minGrid*1.5
    img
      object-fit cover
      aspect-ratio 16 / 9
      width 100%
      transition 0.5s ease-in-out
      :where(a) &:hover
        transform scale(1.05)
  &__text
    display grid
    grid-template-columns 1fr
    gap size-minGrid*3
  &__heading
    font-size size-font--large
    font-weight bold
    color color-base-text
