/*
  _new_
-----------------------------------------------*/
.l-pageHeader
  background color-primary
  font-size 10px
  line-height size-lineHight--base
  font-family "Zen Kaku Gothic New", sans-serif
  display flex
  flex-direction column
  gap size-minGrid*5
  min-height size-minGrid*70
  box-sizing content-box
  margin 0
  padding 150px 0 0 0
  +breakpoint(smd)
    padding-top 100px
  &__content
    display flex
    flex-direction column
    flex-grow 1
    padding-top size-minGrid*5
  +breakpoint(sm)
    min-height size-minGrid*60

.l-pageHeader-simple
  padding 150px 0 0 0
  font-size 10px
  +breakpoint(smd)
    padding-top 100px
