/* 
  _new_
-----------------------------------------------*/
.c-speakerProfile-typeA
  --name-color color-primary
  verticalLayout(size-minGrid)
  line-height 1.5
  &__company
    font-size size-font--xxlarge
    font-weight 700
  &__position
    font-size size-font--large
    font-weight 500
  &__name
    font-size size-font--xxxlarge
    color var(--name-color)
    font-weight 700
    &__small
      font-size 0.8em
    &__after
      font-size size-font--large
  +breakpoint(sm)
    &__company
      font-size size-font--large
    &__position
      font-size 1.4rem
    &__name
      font-size size-font--large
      &__small
        font-size 0.8em
      &__after
        font-size 1.6rem


