/*
  _new_
-----------------------------------------------*/
.c-faq-typeA
  &__item
    &:not(:first-child)
      padding-top size-minGrid*6
    &:not(:last-child)
      border-bottom 1px solid color-border
      padding-bottom size-minGrid*6
  &__content
    verticalLayout(size-minGrid*4)
  &__q,&__a
    position relative
    padding-top 2px
    padding-left size-minGrid*11
    &::before
      position absolute
      top 0
      left 0
      display inline-block
      font-weight bold
      font-family font-lato
      font-size size-font--xlarge
      line-height 1.5
  &__q
    font-size 1.6rem
    &::before
      content: 'Q'
      color color-primary
  &__a
    verticalLayout(size-minGrid*4)
    &::before
      content: 'A'
      color color-secondary
    > section
      margin-top size-minGrid*3
  
  +breakpoint(sm)
    &__item
      &:not(:first-child)
        padding-top size-minGrid*4
      &:not(:last-child)
        padding-bottom size-minGrid*4
    &__content
      gap size-minGrid*3
    &__q,&__a
      position relative
      padding-left size-minGrid*6
      &::before
        font-size size-font--large
    &__q
      font-size 1.4rem
    &__a
      gap size-minGrid*2


/*
  _old_
-----------------------------------------------*/
.c-faq
  &__item
    &:not(:first-child)
      padding-top size-minGrid*6
    &:not(:last-child)
      border-bottom 1px solid color-border
      padding-bottom size-minGrid*6
  &__q,&__a
    position relative
    padding-left size-minGrid*11
    &::before
      position absolute
      top 0
      left 0
      display inline-block
      font-weight bold
      font-family font-lato
      font-size size-font--xlarge
      line-height 1.5
  &__q
    margin-bottom size-minGrid*4
    font-size 1.6rem
    &::before
      content: 'Q'
      color color-primary
  &__a
    verticalLayout(size-minGrid*4)
    &::before
      content: 'A'
      color color-secondary
    > section
      margin-top size-minGrid*3
  
  +breakpoint(sm)
    &__item
      &:not(:first-child)
        padding-top size-minGrid*4
      &:not(:last-child)
        padding-bottom size-minGrid*4
    &__q,&__a
      position relative
      padding-left size-minGrid*6
      &::before
        font-size size-font--large
    &__q
      margin-bottom size-minGrid*3
      font-size 1.4rem
    &__a
      gap size-minGrid*2