.c-pageHeading
  width 100%
  max-width 1440px
  padding size-minGrid*5 size-minGrid*7.5
  display flex
  flex-direction column
  justify-content flex-end
  align-items flex-start
  flex-wrap wrap
  margin 0 auto
  color color-primary-text
  flex-grow 1
  gap size-minGrid*2
  box-sizing border-box
  word-break break-all
  font-family "Zen Kaku Gothic New", sans-serif
  &__info
    flex-basis 100%
    display flex
    justify-content flex-start
    align-items center
    gap size-minGrid*5
  &__heading
    font-size 5.4em
    font-weight 500
    line-height 1.2
    letter-spacing 0.1em
    width 100%
    margin 0
    ^[0]__info + &
      font-size 3em
  &__label
    display block
  &__category
    display block
    font-size 1.5em
    line-height 2
    border 1px solid color-button-standard
    color color-base-text
    background-color color-contentsBackground
    border-radius 1em
    padding 0 size-minGrid*4
  &__date
    font-size: 1.5em
  &__image
    display block
    width 100%
    max-width size-minGrid*300
    height auto
    margin 0 auto
    vertical-align bottom
  
  &.-image
    padding-bottom 0
  
  +breakpoint(md)
    &__heading
      font-size 4.8em
      ^[0]__info + &
        font-size 2.6em

  +breakpoint(sm)
    &__heading
      font-size 3em
      ^[0]__info + &
        font-size 2em
    &__category
      font-size 1.3em
    &.-image
      padding 0

h1.c-pageHeading__heading
  font-family "Zen Kaku Gothic New", sans-serif