.l-simpleHeader
  display flex
  align-items center
  gap size-minGrid*4
  padding size-minGrid size-minGrid*6
  background-color color-black
  height 80px
  color #fff
  &__title
    padding-top 4px
    font-size 12px
    line-height 1.5
  +breakpoint(sm)
    &__title
      font-size 10px
