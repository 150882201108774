/* 
  _new_
-----------------------------------------------*/
.c-list-typeA
  --bg-color color-contentsPointBackground
  --accent-color color-primary
  --text-accent-color color-primary
  verticalLayout(size-minGrid)
  font-size size-font--xlarge
  &__item
    background-color var(--bg-color)
    border-left 6px solid var(--accent-color)
    padding size-minGrid*5 size-minGrid*8
  &__emphasis
    color var(--text-accent-color)
    font-weight 500
  &.-textSmall
    font-size 1.6rem
    & ^[0]__item
      padding size-minGrid*4 size-minGrid*8
  +breakpoint(sm)
    font-size 1.6rem
    &__item
      padding size-minGrid*4 size-minGrid*5


.c-list-typeB
  display inline-block
  &__item
    display flex
    align-items center
    gap size-minGrid*2
    font-weight 500
    border-bottom dashed 1px #15b0bf
    padding-bottom size-minGrid
    &::before
      content ""
      display block
      width 10px
      height 10px
      background-color #15b0bf
      border-radius 50%
    &+&
      margin-top size-minGrid*2
  &__emphasis
    color #15b0bf
    font-size size-font--large


.c-list-typeC
  --text-accent-color color-primary
  verticalLayout(size-minGrid*4)
  border-top solid 1px color-border
  padding-top size-minGrid*4
  font-size size-font--xlarge
  &__item
    display flex
    align-items center
    gap size-minGrid*3
    border-bottom solid 1px color-border
    padding-bottom size-minGrid*4
    &::before
      content ""
      display block
      width 20px
      height 20px
      margin-left size-minGrid*3
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.32' height='20' viewBox='0 0 12.32 20'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ababab;%7D.cls-2%7Bfill:%230071af;%7D%3C/style%3E%3C/defs%3E%3Cg%3E%3Cg id='design'%3E%3Crect class='cls-1' x='-1.08' y='9.48' width='15.22' height='1.98' transform='translate(-5.79 11.21) rotate(-61.45)'/%3E%3Cpath class='cls-2' d='M3.77,17.91a.32.32,0,0,1-.14,0L.15,16A.29.29,0,0,1,0,15.81a.3.3,0,0,1,0-.21L7.31,2.23a.26.26,0,0,1,.16-.13.3.3,0,0,1,.21,0L11.17,4a.27.27,0,0,1,.11.38L4,17.76a.25.25,0,0,1-.16.14ZM.66,15.62l3,1.63,7-12.87-3-1.63Z'/%3E%3Cpath class='cls-2' d='M11.15,4.64l-4-2.16L8.2.59A1.09,1.09,0,0,1,8.87.05a1.05,1.05,0,0,1,.85.09l2,1.09a1.12,1.12,0,0,1,.45,1.52ZM7.93,2.26l3,1.62.76-1.4a.56.56,0,0,0-.22-.76L9.45.63A.56.56,0,0,0,9,.58a.55.55,0,0,0-.34.27Z'/%3E%3Cpath class='cls-2' d='M7,7.75a.31.31,0,0,1-.13,0,.27.27,0,0,1-.11-.38L9.05,3.18a.28.28,0,1,1,.49.27L7.28,7.61A.29.29,0,0,1,7,7.75Z'/%3E%3Cpath class='cls-2' d='M.43,20a.28.28,0,0,1-.28-.27l-.15-4a.28.28,0,0,1,.13-.24.26.26,0,0,1,.28,0L3.9,17.38a.3.3,0,0,1,.15.24.31.31,0,0,1-.13.25L.58,20A.28.28,0,0,1,.43,20Zm.15-3.79.11,3,2.52-1.58Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size contain
      background-repeat no-repeat
  &__emphasis
    color var(--text-accent-color)
    font-weight 500
  +breakpoint(sm)
    font-size 1.6rem


.c-list-bullet
  verticalLayout(size-minGrid)
  &__item
    padding-left 1em
    position relative
    &::before
      content ''
      width .6rem
      height .6rem
      background-color color-secondary
      border-radius 50%
      position absolute
      top 0.7em;
      left 0;
    & > *
      margin-top size-minGrid
    & > ^[0]
      margin-left .25em
      & ^[0]__item
        position relative
        padding-left 1em
        &:not(:first-child)
          margin-top size-minGrid
        &::before
          transform rotate(45deg)
          background-color color-base-text
          border-radius 0
        & > ^[0]
          margin-top size-minGrid
          & ^[0]__item
            position relative
            padding-left 1em
            &:not(:first-child)
              margin-top size-minGrid
            &::before
              border-radius 50%
              background-color transparent
              border 1px solid color-base-text
  &.-gapLarge
    gap size-minGrid*5
  &.-textLarge
    font-size size-font--large
  &.-bulletColorPrimary
    & > ^[0]__item
      &::before
        background-color color-primary
  &.-modifierA
    color color-primary
    & >  ^[0]__item
      &::before
        background-color color-primary
  &.-modifierB
    color color-primary
    font-weight 700
  +breakpoint(sm)
    &.-gapLarge
      gap size-minGrid*2
    &.-textLarge
      font-size 1.6rem


.c-list-notification
  verticalLayout(size-minGrid)
  line-height 1.8
  font-size 1.4rem
  &__item
    padding-left 1.2em
    position relative
    color color-gray-dark
    &::before
      display block
      content "※"
      position absolute
      top 0
      left 0
      margin-right 0.2em
  &.-textSmall
    font-size size-font--small 
  &.-textLarge
    font-size size-font--medium
  &.-asterisk
    & ^[0]__item::before
      content "＊"
  &.-modifierA
    font-weight bold
    background-color color-contentsPointBackground
    padding 10px 14px
    border none
    & ^[0]__item
      color color-primary
  &.-number
    counter-reset number 0
    & ^[0]__item
      padding-left 2em
      &::before
        counter-increment number 1
        content "※"counter(number)
  +breakpoint(sm,min)
    &.-textEnd
      text-align right
      & ^[0]__item
        padding-left 0
        &::before
          position relative
          display inline-block
  +breakpoint(sm)
    font-size size-font--small 
    &.-textLarge
      font-size 1.3rem


.c-list-check
  verticalLayout(size-minGrid)
  &__item
    display flex
    align-items center
    gap size-minGrid*3
    &::before
      content ""
      display block
      width 14px
      height 12px
      flex-shrink 0
      background-size contain
      background-repeat no-repeat
      background-image url('/_assets/img/_common/icon_check.svg')

.c-list-typeD
  verticalLayout(size-minGrid)




/* 
  _old_
-----------------------------------------------*/
contentsWrapperSize = 'calc(%s - ((%s * 10) * 2))' % (size-contentsArea size-minGrid)
.c-list
  &-card
    gapSize = size-minGrid*8
    colSize = 'calc((%s - (%s * 3)) / 4)' % (contentsWrapperSize gapSize)
    display grid
    gap gapSize
    grid-template-columns repeat(auto-fill, minmax(colSize, 1fr))
  &-button
    gapSize = size-minGrid*2
    colSize = 'calc((%s - (%s * 3)) / 4)' % (contentsWrapperSize gapSize)
    display grid
    gap gapSize
    grid-template-columns repeat(auto-fill, minmax(colSize, 1fr))
    &.-dotRight
      gap 0
      grid-template-columns repeat(3, minmax(auto, 1fr))
      ^[1]__button
        @extend .c-button-dotRight
        box-shadow none
        border 1px solid color-border
      +breakpoint(md)
        grid-template-columns 1fr
  &-rectangle
    width 100%
    display grid
    gap size-minGrid*2
    grid-template-columns repeat(3, minmax(auto, 1fr))
    &__item
      display grid
      justify-content center
      align-items center
      background-color color-primary
      color color-primary-text
      font-weight 500
      height size-minGrid*12.5
    +breakpoint(sm)
      grid-template-columns 1fr
  &-pill
    width 100%
    display grid
    gap size-minGrid*2
    grid-template-columns repeat(3, minmax(auto, 1fr))
    &__item
      display grid
      justify-content center
      align-items center
      background-color #15b0bf
      color color-primary-text
      font-weight 500
      height size-minGrid*10
      border-radius 100vh
    +breakpoint(sm)
      grid-template-columns 1fr

  &-button-center
    display flex
    flex-wrap wrap
    gap size-minGrid*5
    justify-content center
    &__item
      display flex
      margin 0
    +breakpoint(sm)
      flex-direction row
      gap size-minGrid*3
      &__item
        width 100%
  &-logo
    display grid
    grid-template-columns repeat(3, 1fr)
    gap size-minGrid*8

  &-anchor
    display grid
    grid-template-columns repeat(auto-fit, minmax(240px, 1fr));
    gap size-minGrid
    &.-col3
      grid-template-columns repeat(auto-fit, minmax(300px, 1fr));
    &.-col5
      grid-template-columns repeat(auto-fit, minmax(172px, 1fr));
    +breakpoint(sm) 
      grid-template-columns repeat(2, 1fr);
      &.-col3,  &.-col5
        grid-template-columns repeat(2, 1fr);
    
  &-link
    &__item
      display inline-block
      margin-right size-minGrid*5

