.c-stepList
  verticalLayout(size-minGrid*4)
  counter-reset step 0
  &Item
    verticalLayout(size-minGrid*1.5)
    counter-increment step 1
    &__title
      display flex
      align-items baseline
      gap size-minGrid*2
      &::before
        display flex
        font-weight bold
        content "STEP " counter(step)
        color color-primary
        padding size-minGrid size-minGrid*2
        border solid 1px color-primary
        justify-content center
        align-items center
        // margin-right size-minGrid*2
        flex-shrink 0
        flex-grow 0
