.l-simpleFooter
  display flex
  justify-content center
  align-items center
  gap 12px
  padding size-minGrid*4
  &__icon
    display block
    width 20px
    img
      display block
  &__copy
    font-size 10px

footer.l-simpleFooter
  background-color transparent