/* 
  _new_
-----------------------------------------------*/
.c-shopInfo-typeA
  border 1px solid color-border
  border-radius 5px
  padding size-minGrid*5 size-minGrid*10 size-minGrid*10
  &__inner
    display grid
    grid-template-columns 250px 1fr
    // grid-template-columns 360px 1fr
    gap size-minGrid*12
  &__thumbnail
    margin-top size-minGrid*5
    position relative
    text-align center
    overflow hidden
    &__image
      position absolute
      top 0
      left 0
      max-width 100%
      height auto
  &__content
    verticalLayout(size-minGrid*5)
    &__header
      verticalLayout(size-minGrid)
  &__label
    display flex
    flex-wrap wrap
    gap size-minGrid
    justify-content flex-end
  &__company
    line-height 1.5
  &__link
  &__text
    font-size 1.4rem

  +breakpoint(md)
    &__content
      &__header
        gap size-minGrid*2
  +breakpoint(smd)
    padding size-minGrid*5
    verticalLayout(size-minGrid*3)
    &__inner
      grid-template-columns 1fr
      gap size-minGrid*5
    &__content
      &__header
        gap size-minGrid*5
    &__thumbnail
      margin auto
      &__image
        position static
        width 250px
        height auto
        aspect-ratio 16 / 9
        object-fit cover
        object-position 0 0
    &__label
      justify-content flex-start

