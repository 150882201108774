/* 
  _old_
-----------------------------------------------*/
// c-blockに変更

.c-box
  padding size-minGrid*5
  &.-pd-large
    padding size-minGrid*8
  &.-pd-large-v
    padding size-minGrid*8 size-minGrid*5
  &.-pd-large-h
    padding size-minGrid*5 size-minGrid*8
  &.-bordered
    border 1px solid color-border
  &.-borderedLight
    border 1px solid color-contentsPointBorder
  &.-borderedPrimary
    border 1px solid color-primary
  &.-borderedRed
    border 1px solid color-red
  &.-bg
    background color-contentsPointBackground
  &.-bg-gray
    background color-contentsBackground
  &.-bg-white
    background #fff
  &.-border-m
    border-width 3px
  &.-border-b
    border-width 6px
  
  +breakpoint(sm)
    &.-pd-large
      padding size-minGrid*5