/* 
  _old_
-----------------------------------------------*/
// 削除予定

.u-block
  display block

.u-block-upperSm
  +breakpoint(sm, min)
    display block

.u-block-upperMd
  +breakpoint(md, min)
    display block