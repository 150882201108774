.c-pageFooter-customer
  width 100%
  max-width size-contentsArea
  padding size-minGrid*5 size-minGrid*7.5
  display flex
  flex-direction column
  justify-content center
  align-items center
  margin 0 auto
  color color-primary-text
  gap size-minGrid*10
  box-sizing border-box
  *
    box-sizing inherit
  &__heading
    color color-primary-text
    line-height 1.5
    font-size clamp(20px,vw(30),30px)
    text-align center
    font-weight bold
    font-family "Zen Kaku Gothic New", sans-serif
  &__lead
    color color-primary-text
    text-align center
    font-size clamp(16px,vw(20),20px)
    font-weight bold
    &__inner
      display inline-block
  &__nav
    display grid
    grid-template-columns repeat(2,1fr)
    gap size-minGrid*10 4%
    width 100%
    &__item
      display grid
      grid-template-columns 70% 1fr
      align-items center
      gap size-minGrid*2
      background-color #fff
      padding size-minGrid*7
      &.-mail
        & ^[0]__nav__content
          margin-right size-minGrid*6
    &__image
      text-align center
    &__label
      display inline-block
      margin-bottom size-minGrid
      padding 2px 1em
      border-radius 100px
      letter-spacing 0.1em
      background-color color-primary
      color #fff
      font-size clamp(12px,vw(15),15px)
      font-family "Zen Kaku Gothic New", sans-serif
    &__heading
      margin-bottom size-minGrid*3
      font-weight bold
      color color-primary
      line-height 1.5
      font-size clamp(16px,vw(20),20px)
      font-family "Zen Kaku Gothic New", sans-serif
      &__inner
        display block
    &__button
      &__link
        position relative
        display flex
        align-items center
        max-width: 100%
        margin 0 auto
        padding size-minGrid*4 size-minGrid*9  size-minGrid*4 size-minGrid*6
        background color-button-standard
        color color-primary-text
        line-height 1.5
        border-radius 4px
        border 1px solid color-button-standard
        transition all .2s ease-out
        font-size 15px
        font-weight 500
        &:visited
          color color-primary-text
        &::after
          position absolute
          right size-minGrid*4
          top 50%
          transform translateY(-50%)
          content ''
          display block
          background color-primary-text
          width 6px
          height 6px
          border-radius 50%
          transition all .2s ease-out
        &:hover
          text-decoration none
          background-color #fff
          color color-button-standard
          &::after 
            background-color color-button-standard
            transform translateY(-50%) scale(1.2)
  &__tel
    line-height 1.2
    color color-primary
    &__link
      display inline-block
      color color-primary
    &__num
      font-size clamp(32px,vw(42),48px)
      font-weight bold
      font-family 'Lato', sans-serif
      &::before
        content ''
        top 3px
        display inline-block
        margin-right size-minGrid*3
        background url('../img/_common/icon_tel_primary.svg')
        background-repeat no-repeat
        background-size contain
        width clamp(1px,vw(24),24px)
        height clamp(1px,vw(35),35px)
    &__small
      text-align right
      font-size 15px

  +breakpoint(md)
    &__nav
      &__item
        grid-template-columns 1fr
        align-content start
        gap size-minGrid*5
        text-align center
        &.-mail
          & ^[0]__nav__content
            margin-right 0
      &__content
        order 2
        align-content start
        text-align center
        display block
      &__content__inner
        display inline-block
        text-align left
      &__heading
        &__inner
          display inline-block
      &__image
        order 1

  +breakpoint(sm,min)
    &__tel
      pointer-events none

  +breakpoint(sm)
    &__nav
      grid-template-columns 1fr
      &__label
        padding 2px 2em
      &__item
        justify-content center
        gap size-minGrid*4
        &.-mail
          // gap size-minGrid*4
      &__button
        &__link
          max-width 368px
          padding size-minGrid*5 size-minGrid*8 size-minGrid*5  size-minGrid*4
    &__tel
      &__num
        &::before
          content none