/* 
  _new_
-----------------------------------------------*/
for num in (1..4)
  .l-column-{num}
    display grid
    gap size-minGrid*10
    grid-template-columns repeat(num, minmax(auto,1fr))
    &.-verticalCenter
      align-items center
.l-column-4
  gap size-minGrid*8
+breakpoint(md)
  for num in (2..4)
    .l-column-{num}
      gap size-minGrid*8
      grid-template-columns repeat(num - 1, minmax(auto,1fr))
+breakpoint(sm)
  for num in (3..4)
    .l-column-{num}
      grid-template-columns 1fr


.l-column-typeA
  display flex
  gap size-minGrid*10
  &.-leftGrow
    > *:first-child
      flex-grow 1
      flex-shrink 1
      flex-basis auto
    > *:nth-child(2)
      flex-shrink 0
  &.-rightGrow
    > *:first-child
      flex-shrink 0
    > *:nth-child(2)
      flex-grow 1
      flex-shrink 1
      flex-basis auto
  &.-verticalCenter
    align-items center
  &.-verticalBottom
    align-items end
  +breakpoint(md)
    flex-direction column
    &.-verticalBottom
      align-items normal
  +breakpoint(sm)
    gap size-minGrid*5

.l-column-typeB
  display grid
  gap size-minGrid*10
  &.-leftLarge
    grid-template-columns 2fr 1fr
  &.-rightLarge
    grid-template-columns 1fr 2fr
  &.-verticalCenter
    align-items center
  +breakpoint(md)
    &.-leftLarge
    &.-rightLarge
      grid-template-columns 1fr
  +breakpoint(sm)
    gap size-minGrid*5

.l-column-typeC
  display flex
  align-items center
  justify-content center
  gap size-minGrid*10
  +breakpoint(md)
    flex-direction column
  +breakpoint(sm)
    gap size-minGrid*5

.l-column-typeD
  display grid
  gap size-minGrid*10
  grid-template-columns repeat(auto-fit, minmax(330px, 1fr))
  > *:only-child
    max-width 'calc(50% - (%s * 5))' %size-minGrid
  +breakpoint(md)
    grid-template-columns repeat(2,1fr)
    > *:only-child
      max-width 100%
  +breakpoint(sm)
    grid-template-columns 1fr

.l-column-typeE
  display grid
  grid-template-columns repeat(auto-fit, minmax(240px, 1fr));
  gap size-minGrid*3

.l-column-typeF
  display flex
  flex-wrap wrap
  gap size-minGrid*3
  &.-gapLarge
    gap size-minGrid*5 size-minGrid*10
  +breakpoint(sm)
    gap size-minGrid*1 size-minGrid*2
    &.-gapLarge
      gap size-minGrid*3 size-minGrid*5


/* 
  _old_
-----------------------------------------------*/
.l-column-2to1
  display grid
  gap size-minGrid*8
  grid-template-columns 2fr 1fr
  +breakpoint(md)
    grid-template-columns 1fr
