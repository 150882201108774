/* 
  _old_
-----------------------------------------------*/
// u-displayに変更

.u-view-upperSm
  display block
  +breakpoint(sm)
    display none

.u-view-sm
  display none
  +breakpoint(sm)
    display block
