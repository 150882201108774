/* 
  _new_
-----------------------------------------------*/
.c-table-typeA
  --main-bg-color color-tableHeader
  --main-text-color color-tableHeader-text
  --sub-bg-color color-contentsPointBackground
  border-collapse collapse
  width 100%
  max-width 100%
  &__headerCell
  &__dataCell
  &__caption
    padding size-minGrid*3 size-minGrid*5
  &__caption
  &__header
    font-weight bold
    font-size size-font--large
  &__caption:first-child,
  &__header:first-child ^[0]__headerCell,
  colgroup:first-child + ^[0]__header ^[0]__headerCell
    background-color var(--main-bg-color)
    color var(--main-text-color)
  &__headerCell
    background-color color-tableHeader-cell
  &__headerCell
  &__dataCell
    border 1px solid color-tableBorder
    vertical-align middle
  &__body
    & ^[0]__headerCell
      line-height 1.5
  &.-bordered
    .c-table-typeA__body .c-table-typeA__row:nth-child(even) .c-table-typeA__dataCell
      background-color var(--sub-bg-color)
  &.-headerCellCenter
    & ^[0]__headerCell
      text-align center
  &.-dataCellCenter
    & ^[0]__dataCell
      text-align center
  +breakpoint(sm)
    &__caption
    &__headerCell
    &__dataCell
      padding size-minGrid*3
    &__caption
    &__header
      font-size size-font--medium


.c-table-typeB
  --main-bg-color color-tableHeader
  --main-text-color color-tableHeader-text
  --sub-bg-color color-contentsPointBackground
  border-collapse collapse
  width 100%
  max-width 100%
  &__headerCell
    font-weight bold
  &__headerCell
  &__dataCell
  &__caption
    padding size-minGrid*3 size-minGrid*5
  &__caption
  &__header
    font-weight bold
    font-size size-font--large
  &__caption:first-child,
  &__header:first-child ^[0]__headerCell,
  colgroup:first-child + ^[0]__header ^[0]__headerCell
    background-color var(--main-bg-color)
    color var(--main-text-color)
    border-top 1px solid var(--main-bg-color)
    &:first-child
      border-left 1px solid var(--main-bg-color)
    &:last-child
      border-right 1px solid var(--main-bg-color)
  &__headerCell
  &__dataCell
    border 1px solid color-tableBorder
    vertical-align middle
    text-align center
  &__dataCell
    font-size 2.2rem
    color color-primary
  &__headerCell
  &__body
    & ^[0]__headerCell
      line-height 1.5
      font-weight normal
    & ^[0]__dataCell:nth-child(even)
      background-color color-tableHeader-cell
    & ^[0]__headerCell
      font-family font-lato
      padding size-minGrid*3 size-minGrid*8
      &__label
        font-weight bold
    & ^[0]__headerCell__inner
      display flex
      flex-direction column
      gap size-minGrid*2
  +breakpoint(sm)
    &__caption
    &__headerCell
    &__dataCell
      padding size-minGrid*3
    &__caption
    &__header
      font-size size-font--medium


.l-scrollContainer-scrollX
  +breakpoint(sm)
    overflow scroll
    padding-bottom size-minGrid*3
    table
      min-width 800px



/* 
  _old_
-----------------------------------------------*/
.c-table
  border-collapse collapse
  width 100%
  max-width 100%
  &__caption
    background-color color-tableHeader
    color color-tableHeader-text
    padding size-minGrid*4
    font-weight bold
    line-height 1.5
    &__heading
      display block
      position relative
      font-size size-font--xxlarge
      padding-left 1em
      &::before
        content "";
        border-style solid;
        border-color transparent;
        border-left-color #ffffff;
        border-width 0.3em 0 0.3em 0.5em;
        position absolute;
        top 0.5em;
        left 0;
    &__desc
      display block
      font-size size-font--medium
      font-weight normal
  &__header
    & ^[0]__headerCell
      background-color color-tableHeader
      color color-tableHeader-text 
  &__headerCell
    background-color color-tableHeader-cell
    line-height 1.5
    &.-bg-secondary
      background-color color-tableHeaderSecondary
    &.-has-label
      & ^[0]__headerCell__inner
        display flex
        justify-content space-between
        align-items center
        gap 0 size-minGrid*3
      & ^[0]__headerCell__conetnt
        flex-grow 1
        flex-shrink 1
        flex-basis auto
      & ^[0]__headerCell__label
        flex-shrink 0
        max-width 50%
  &__dataCell
    line-height 1.6
  &__headerCell
  &__dataCell
    border 1px solid color-tableBorder
    padding size-minGrid*4
    vertical-align middle

  // tbodyのth：メインカラー、tbodyのtd：背景交互
  &.-var2
    & ^[0]__headerCell
      background-color color-tableHeader
      color color-tableHeader-text
    & ^[0]__row:nth-child(even)
      background-color color-contentsPointBackground

  // tbodyのth,td：背景交互
  &.-var3
    & ^[0]__body
      & ^[0]__row:nth-child(odd)
        & ^[0]__headerCell
        & ^[0]__dataCell
          background-color transparent
      & ^[0]__row:nth-child(even)
        & ^[0]__headerCell
        & ^[0]__dataCell
          background-color color-contentsPointBackground

  // thead：グレー、tbody：無色
  &.-var4
    & ^[0]__headerCell
      background-color color-tableHeader-cell
      color color-base-text
    & ^[0]__body
      & ^[0]__headerCell
        background-color transparent

  +breakpoint(sm)
    &__caption
      padding size-minGrid*3
      &__heading
        font-size 1.6rem
      &__desc
        font-size 1.2rem

    &__headerCell
    &__dataCell
      padding size-minGrid*3

    &.-sm-block
      & ^[0]__headerCell
      & ^[0]__dataCell
        display block

      & ^[0]__headerCell
        border-bottom none
      & ^[0]__row
        &:not(:last-child)
          & ^[0]__dataCell
              border-bottom none

