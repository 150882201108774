/*
  _new_
-----------------------------------------------*/
.c-movieArea
  padding size-minGrid*6
  background-color color-contentsBackground
  display grid
  align-items center
  justify-content center
  gap size-minGrid*6
  grid-template-columns 1fr
  &__text
    verticalLayout(size-minGrid*4)
  &__heading
    font-weight 500
    font-size size-font--large
    text-align center
    line-height 1.3
  &__movie
    aspect-ratio: 16 / 9
    width 100%
    max-height 100%
    & iframe
      width 100%
      height 100%
  +breakpoint(smd, min)
    grid-template-columns 1fr auto;
    container-type inline-size
    &__heading, &__text
      grid-column 1
    &__heading:nth-last-child(2), &__text:first-child
      grid-row 1 / 3
    &__movie
      grid-column 2
      grid-row 1 / 3
      min-width 60cqw
      &:only-child
        grid-column 1 / 3
        grid-row 1
        max-width 60cqw
        justify-self center


/*
  _old_
-----------------------------------------------*/
.c-iframe
  iframe
    aspect-ratio: 16 / 9
    width 100%
    height 100%